import React from 'react';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { useParams } from 'react-router-dom';
import TicketService from '../services/TicketService';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import car from '../assets/img/car.svg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import { IconButton } from '@mui/material';
import { getConfig } from 'config';
import Loading from 'components/Loading';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import TicketReceiptTransactions from 'views/TicketReceiptTransactions';

export const getMappedStatus = (status) => {
  if (status === 'unparked') {
    return 'Start not started';
  }
  if (status === 'parked') {
    return 'Service in Progress';
  }
  if (status === 'retrieved') {
    return 'Service Complete';
  }
  return String(status).toUpperCase();
};

const useStyles = makeStyles({
  ...styles,

  inputLabel: {
    color: '#008BE8',
    font: 'normal normal bold 16px/19px Lato',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  sidetext: {
    color: '#324152',
    float: 'left',
    paddingLeft: '10px',
  },
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingBottom: '5%',
    marginBottom: '5%',
  },
  media: {
    height: 140,
  },
  containerRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    width: 500,
    height: 450,
  },
});

const { apiOrigin = 'http://localhost:3001' } = getConfig();

function TicketReceipt() {
  const classes = useStyles();
  const [ticket, setTicket] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);
  let { id } = useParams();

  const [parkTime, setParkTime] = React.useState('');

  const updateTime = (startTime, endTime) => {
    var ms = moment(startTime, 'DD/MM/YYYY HH:mm:ss').diff(moment(endTime, 'DD/MM/YYYY HH:mm:ss'));
    var d = moment.duration(ms);
    setParkTime(Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss'));
  };

  function getDuration(startTime, endTime) {
    // start time and end time
    startTime = moment(startTime);
    endTime = moment(endTime);
    var ms = moment(endTime, 'DD/MM/YYYY HH:mm:ss').diff(moment(startTime, 'DD/MM/YYYY HH:mm:ss'));
    var d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
  }

  const fetchTicket = async () => {
    console.log('Fetchticket?');
    const api = axios.create({
      baseURL: `${apiOrigin}/`,
    });
    TicketService.init(api);

    await TicketService.getTicketReceiptByToken(id).then((res) => {
      setTicket(res);
      setIsLoading(false);
    });
  };
  React.useEffect(() => {
    fetchTicket();
  }, []);

  React.useEffect(() => {
    if (ticket && ticket.start_time && !ticket.end_time) {
      const intervalRef = setInterval(function () {
        var now = moment();
        var then = moment(ticket.start_time);
        updateTime(now, then);
      }, 1000);
      return () => {
        clearInterval(intervalRef);
      };
    }
  });

  if (isLoading) {
    return <Loading />;
  }
  return (
    <GridContainer justify='center'>
      <GridItem xs={12} sm={12} md={10} align='center'>
        <Paper elevation={5} className={classes.root}>
          <CardContent>
            <CardHeader
              action={
                <IconButton
                  aria-label='settings'
                  size='large'
                  onClick={() => {
                    fetchTicket();
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              }
              title='Ticket Detail'
              subheader={ticket.token}
            />
            <img src={car} alt='Home header' width='70%' />
            <List>
              <ListItem>
                <ListItemText primary={ticket.name} secondary='Name' />
              </ListItem>
              <Divider component='li' />
              <ListItem>
                <ListItemText primary={ticket.phone} secondary='Phone Number' />
              </ListItem>
              <Divider component='li' />
              <ListItem>
                <ListItemText
                  primary={ticket.vehicle_type + ' (' + ticket.color + ')'}
                  secondary='Vehicle Type (color)'
                />
                <ListItemText primary={ticket.plate} secondary='Plate Number' />
              </ListItem>
              <Divider component='li' />
              <ListItem>
                <ListItemText
                  primary={
                    <Chip
                      label={String(ticket.payment_status).toUpperCase()}
                      style={{
                        backgroundColor: ticket.payment_status === 'paid' ? 'green' : 'red',
                        color: 'white',
                      }}
                    />
                  }
                  secondary='Payment Status'
                />
                <ListItemText
                  primary={
                    <Chip
                      label={getMappedStatus(ticket.vehicle_status)}
                      color={ticket.vehicle_status === 'retrieved' ? 'primary' : 'secondary'}
                    />
                  }
                  secondary='Vehicle Status'
                  style={{ marginLeft: '10%' }}
                />
              </ListItem>
              <Divider component='li' />
              {ticket.vehicle_status === 'parked' && (
                <ListItem>
                  <ListItemText
                    primary={moment(ticket.start_time).format('MM/DD/YYYY HH:mm:ss')}
                    secondary='Parked Time'
                  />
                  <ListItemText primary={parkTime} secondary='Duration' />
                </ListItem>
              )}
              {ticket.vehicle_status === 'retrieved' && (
                <ListItem>
                  <ListItemText
                    primary={moment(ticket.end_time).format('MM/DD/YYYY HH:mm:ss')}
                    secondary='Retrieved Time'
                  />
                  <ListItemText
                    primary={getDuration(ticket.start_time, ticket.end_time)}
                    secondary='Duration'
                  />
                  <ListItemText primary={'$' + ticket.total_amount.toFixed(2)} secondary='Amount' />
                </ListItem>
              )}{' '}
              {ticket.additionalFees && ticket.additionalFees.length > 0 && (
                <ListItem>
                  <h4>Additional Fees</h4>
                </ListItem>
              )}
              {ticket.additionalFees && ticket.additionalFees.length > 0 && (
                <ListItem>
                  {ticket.additionalFees.map((item) => (
                    <ListItemText
                      key={item.name}
                      primary={`$${item.amount.toFixed(2)}`}
                      secondary={item.name}
                    />
                  ))}
                </ListItem>
              )}{' '}
              {ticket.attachments && ticket.attachments.length && (
                <ImageList cellHeight={200} cols={2} className={classes.gridList}>
                  {ticket.attachments.map((item, index) => {
                    return (
                      <ImageListItem key={index}>
                        <a href={item.url} target='_blank' rel='noopener noreferrer'>
                          <img src={item.url} alt={item.name} />
                          <ImageListItemBar title={item.name} />
                        </a>
                      </ImageListItem>
                    );
                  }) || null}
                </ImageList>
              )}
            </List>
          </CardContent>
          <TicketReceiptTransactions transactions={ticket.transactions} />
        </Paper>
      </GridItem>
    </GridContainer>
  );
}

export default TicketReceipt;
