import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from 'components/Grid/GridItem.js';
import { useNavigate } from 'react-router-dom';
import { Context } from 'Store';
import useAPI from 'useAPI';
import VehicleService from '../../services/VehicleService';
import NotificationService from '../../services/NotificationService';
import { useLocation } from 'react-router-dom';
import { default as reducerTypes } from '../../Reducer/types';
import mixpanel from "mixpanel-browser"

import {
  Box,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  TextField,
  FormControl,
  Select,
  Grid,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { CheckRounded } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning'

const useStyles = makeStyles((theme) => ({
  ...styles,
  customTextField: {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    flexBasis: 'auto',
  },
  inputLabel: {
    color: '#008BE8',
    font: 'normal normal bold 16px/19px Lato',
  },
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectField: {
    textAlign: 'left',
  },
  checkbox: {
    color: 'black',
  }
}));

function VehicleForm({ id = null }) {
  let navigate = useNavigate();
  const classes = useStyles();
  const { state, dispatch } = useContext(Context);
  const { vehicles } = state;
  const isFirstVehicle = !vehicles || vehicles.length === 0;
  const api = useAPI();
  const [saving, setSaving] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleYears, setVehicleYears] = useState([]);
  const [vehicleMake, setVehicleMake] = useState(null);
  const [vehicleModel, setVehicleModel] = useState(null);
  const [vehicleYear, setVehicleYear] = useState(null);
  const [specificModel, setSpecificModel] = useState(null);
  const [vehicleIsDefault, setVehicleIsDefault] = useState(!!isFirstVehicle);
  const [vehicleIsEV, setVehicleIsEV] = useState(false);
  const [timerRef, setTimerRef] = useState(null);
  const [cantFindVehicle, setCantFindVehicle] = useState(false);
  const [cantFindVehicleNotificationSent, setCantFindVehicleNotificationSent] = useState(false);
  const vehicleInitializationFlags = useRef({
    make: false,
    model: false,
    year: false,
  });

  const [vehicle, setVehicle] = useState(null);
  const [errors, setErrors] = useState({
    make: '',
    model: '',
    year: '',
  });

  const vehicleIsOversize = () => {
    return specificModel?.type === 'OVERSIZE';
  }

  const UNSAVED_CHANGES_ALERT = {
    label: 'You have unsaved changes',
    success: false,
    color: '#FC9F0D',
  }
  const OVERSIZE_VEHICLE_ALERT = {
    label: 'Vehicle is too big to fit in our automated carousels.',
    success: false,
    color: 'red',
  }

  const [isFromPark, setIsFromPark] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('park')) {
      setIsFromPark(true);
    }
  }, []);

  useEffect(() => {
    VehicleService.init(api);
    NotificationService.init(api);
    if (id) {
      fetchUserVehicle();
    }
  }, [api]);

  const fetchUserVehicle = async () => {
    const res = await VehicleService.getVehicleById(id);
    setVehicle(res);
  };

  const isChanged = () => {
    if (!vehicle) { // no changed flag with new vehicle
      return false;
    }
    return vehicle.make_id !== vehicleMake?.id ||
      vehicle.model_id !== vehicleModel?.id ||
      vehicle.year !== vehicleYear ||
      vehicle.is_ev !== vehicleIsEV ||
      vehicle.is_default !== vehicleIsDefault;
  }

  /**
   * Handle initial vehicle loading
   */
  useEffect(() => {
    if (vehicle) {
      setVehicleIsDefault(vehicle.is_default);
      setVehicleIsEV(vehicle.is_ev);
      fetchVehicleMakes(vehicle.make);
      fetchVehicleModelsByMake(vehicle.make);
      fetchVehicleYearsByModel(vehicle.model_id);
      getVehicleModelByYear(vehicle.model_id, vehicle.year);
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehicleMakes.length > 0) {
      if (vehicle && !vehicleInitializationFlags.current.make) {
        const matchedMake = vehicleMakes.find(make => make.id === vehicle.make_id);
        vehicleInitializationFlags.current.make = true;
        setVehicleMake(matchedMake);
      }
      updatingMakesFlag.current = false;
    }
  }, [vehicleMakes]);

  useEffect(() => {
    if (vehicleModels.length > 0 && vehicle && !vehicleInitializationFlags.current.model) {
      const model = vehicleModels.find(model => model.id === vehicle.model_id)
      vehicleInitializationFlags.current.model = true;
      setVehicleModel(model);
    }
  }, [vehicleModels]);

  useEffect(() => {
    if (vehicleYears.length > 0 && vehicle && !vehicleInitializationFlags.current.year) {
      const year = vehicleYears.find(year => year === vehicle.year);
      vehicleInitializationFlags.current.year = true;
      setVehicleYear(year);
    }
  }, [vehicleYears]);

  const handleMakeChange = (make) => {
    setSpecificModel(null);
    setVehicleModel(null);
    setVehicleModels([]);
    setVehicleYear(null);
    setVehicleYears([]);
    if (make) {
      setVehicleMake(make);
      fetchVehicleModelsByMake(make.name);
    } else {
      setVehicleMake(null);
      setVehicleMakes([]);
    }
  }

  const handleModelChange = (modelId) => {
    setSpecificModel(null);
    setVehicleYear(null);
    setVehicleYears([]);
    setVehicleModel(vehicleModels.find(model => model.id === modelId));
    fetchVehicleYearsByModel(modelId);
  }

  const handleYearChange = (year) => {
    setVehicleYear(year);
    if (year === '') {
      setSpecificModel(null);
    } else if (vehicleModel) {
      getVehicleModelByYear(vehicleModel.id, year);
    }
  }

  useEffect(() => {
    if (specificModel) {
      if (specificModel.is_ev) {
        setVehicleIsEV(true);
      } else if (specificModel.is_ev === 0) { // if NULL then don't change is_ev value
        setVehicleIsEV(false);
      }
    }
  }, [specificModel]);

  const updatingMakesFlag = useRef(false);
  const fetchVehicleMakes = async (search) => {
    if (search.length > 2) {
      const res = await VehicleService.getVehicleMakes(search);
      updatingMakesFlag.current = true;
      setVehicleMakes(res);
    }
  };

  const fetchVehicleModelsByMake = async (make) => {
    try {
      const res = await VehicleService.getVehicleModelsByMake(make)
      setVehicleModel(null);
      setVehicleModels(res);
    } catch (err) {
      alert(err);
    }
  };

  const fetchVehicleYearsByModel = async (modelId) => {
    try {
      const res = await VehicleService.getVehicleYearsByModel(modelId);
      setVehicleYears(res);
    } catch (err) {
      alert(err);
    }
  }

  const getVehicleModelByYear = async (modelId, year) => {
    try {
      const res = await VehicleService.getVehicleModel(modelId, year);
      setSpecificModel(res);
    } catch (err) {
      alert(err);
    }
  }

  const notifyVehicleNotFound = async () => {
    try {
      await NotificationService.sendUserMessage("User can't find vehicle to add");
      setCantFindVehicleNotificationSent(true);
      setTimeout(() => {
        navigateOnward();
      }, 1000);
    } catch (err) {
      alert(err);
    }
  }

  const saveVehicle = async () => {
    mixpanel.track('SAVE_VEHICLE');
    let error = false;
    let newErrors = errors;
    if (!vehicleMake) {
      newErrors.make = 'error';
      error = true;
    }
    if (!vehicleModel) {
      newErrors.model = 'error';
      error = true;
    }
    if (!vehicleYear) {
      newErrors.year = 'error';
      error = true;
    }
    if (!specificModel) {
      console.error('No specific model!');
      error = true;
    }

    setErrors({ ...newErrors });

    if (error) return;
    setSaving(true);
    await VehicleService.storeVehicle({
      id,
      vehicle_identifier: specificModel.vehicle_identifier,
      is_ev: vehicleIsEV,
      is_default: vehicleIsDefault,
    });

    // Refresh vehicle list in state
    const allVehicles = await VehicleService.getVehicles();
    dispatch({ type: reducerTypes.SET_VEHICLES, payload: allVehicles });
    setSaving(false);
    navigateOnward();
  };

  const navigateOnward = () => {
    if (isFromPark) {
      setTimeout(() => {
        navigate('/park/vehicle/select');
      }, 1000);
    } else {
      setTimeout(() => {
        navigate('/vehicles/manage');
      }, 1000);
    }
  }

  const isEVDisabled = () => {
    return !!(specificModel && specificModel.is_ev !== null);
  }

  const AlertComponent = ({label}) => {
    AlertComponent.propTypes = {
      label: PropTypes.string.isRequired,
    }
    return (
      <GridItem xs={12} sm={12} md={8} align='center'>
        {
          <Typography
            variant='body1'
            style={{
              padding: 15,
              color: `${label.color}`,
            }}
          >
            <>
              {(label.success && <CheckRounded />) || <WarningIcon />}
              {label.label}
            </>
          </Typography> }
      </GridItem>
    )
  }

  const saveDisabled = () => {
    if (cantFindVehicle) {
      return false;
    }
    if (!specificModel) {
      return true;
    }
    if (saving) {
      return true;
    }
    if (!id) {
      return false;
    }
    if (!isChanged()) {
      return true;
    }
    return false;
  }

  return (
    <>
      {vehicleIsOversize() ?
        <AlertComponent label={OVERSIZE_VEHICLE_ALERT}/> :
        isChanged() ? <AlertComponent label={UNSAVED_CHANGES_ALERT}/> : null
      }

      {cantFindVehicleNotificationSent &&
        <Snackbar
          open={true}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity='success'>
            Notification sent
          </Alert>
        </Snackbar>
      }

      <GridItem xs={12} sm={12} md={8}>
        <Box pb={5} className={classes.inputLabel}>

          {/* Make */}
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Make:
            </Grid>
            <Grid item xs={9} sm={9}>
              <Autocomplete
                id='vehicle-make'
                fullWidth
                margin='dense'
                value={vehicleMake || ''}
                options={vehicleMakes}
                getOptionLabel={(option) => (option.name || '')}
                isOptionEqualToValue={(option, value) => value === '' || option.id === value.id || updatingMakesFlag.current}

                onInputChange={(event, val) => {
                  if (timerRef) {
                    clearTimeout(timerRef);
                  }
                  if (val?.length > 2) {
                    setTimerRef(
                      setTimeout(() => {
                        fetchVehicleMakes(val);
                      }, 500),
                    );
                  }
                }}

                onChange={(event, newValue) => {
                  handleMakeChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Vehicle Manufacturer (ex. Honda, BMW)'
                    margin='dense'
                    autoFocus={true}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* Model */}
          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Model:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormControl fullWidth margin='dense'>
                <InputLabel id="vehicle-model-label">Select model</InputLabel>
                <Select
                  id='vehicle-model'
                  value={vehicleModel?.id || ''}
                  onChange={(e) => {
                    handleModelChange(e.target.value);
                  }}
                  label='Select model'
                  classes={{
                    root: classes.selectField,
                  }}
                >
                  <MenuItem
                    disabled={true}
                    value={''}
                    key={'select-model'}
                  >
                    Select model
                  </MenuItem>

                  {vehicleModels.map(val => (
                    <MenuItem
                      value={val.id}
                      key={`${val.id}`}
                    >
                      {`${val.name}`}
                    </MenuItem>
                  ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Year */}
          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Year:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormControl fullWidth margin='dense'>
                <InputLabel id="vehicle-year-label">Select year</InputLabel>
                <Select
                  id='vehicle-year'
                  value={vehicleYear || ''}
                  onChange={(e) => {
                    handleYearChange(e.target.value);
                  }}
                  label='Select year'
                  classes={{
                    root: classes.selectField,
                  }}
                >
                  <MenuItem
                    disabled={true}
                    value={''}
                    key={'select-year'}
                  >
                    Select year
                  </MenuItem>
                  <MenuItem
                    value={''} // Set to empty string or another value that indicates no selection
                    key={'clear-selection'}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Clear selection
                  </MenuItem>
                  {vehicleYears.map(val => (
                    <MenuItem
                      value={val}
                      key={`${val}`}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {`${val}`}
                    </MenuItem>
                  ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={9} sm={9} md={9}>
              <FormControlLabel
                control={
                  <Checkbox checked={vehicleIsEV} onChange={(e) => setVehicleIsEV(e.target.checked)} />
                }
                label='Electric vehicle (EV)?'
                disabled={isEVDisabled()}
                className={classes.checkbox}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={9} sm={9} md={9}>
              <FormControlLabel
                control={
                  <Checkbox checked={vehicleIsDefault} onChange={(e) => setVehicleIsDefault(e.target.checked)} />
                }
                label='Set as default vehicle'
                disabled={!!isFirstVehicle}
                className={classes.checkbox}
              />
            </Grid>
          </Grid>

          {!specificModel &&
            <Grid
              container
              direction='row'
              alignItems='center'
              item
              xs={12}
              sm={12}
              md={12}
              align='center'
              spacing={0}
            >
              <Grid item xs={9} sm={9} md={9}>
                <FormControlLabel
                  control={
                    <Checkbox checked={cantFindVehicle} onChange={(e) => {setCantFindVehicle(e.target.checked)}} />
                  }
                  label="Can't find my vehicle"
                  className={classes.checkbox}
                />
              </Grid>
            </Grid>
          }

          <Button
            onClick={() => {
              if (cantFindVehicle) {
                notifyVehicleNotFound();
              } else {
                saveVehicle();
              }
            }}
            variant='contained'
            fullWidth
            style={{
              marginTop: 20,
              color: 'white',
              background: saveDisabled() ? 'lightGray' : cantFindVehicle ? 'red' : '#FC9F0D',
            }}
            disabled={saveDisabled()}
          >
            {cantFindVehicle ? 'Notify Us' : isFromPark ? 'Save & Park' : 'Save Changes'}
          </Button>
          {saving && <CircularProgress size={24} className={classes.buttonProgress} />}

          <Dialog
            open={infoModal}
            onClose={() => setInfoModal(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Info'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {/* {modalBody} */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setInfoModal(false)} color='primary'>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </GridItem>
    </>
  );
}

VehicleForm.propTypes = {
  id: PropTypes.string,
};

VehicleForm.defaultProps = {
  id: null,
};

export default VehicleForm;
