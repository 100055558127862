import { Button } from "@mui/material"
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export default function HelpButton({width = '100%', marginTop = '10px'}) {
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: 'red',
        color: 'white',
        padding: '10px 20px',
        '&:hover': {
          backgroundColor: 'darkred',
        },
        marginTop,
        font: 'normal normal 900 16pt Lato',
        width,
        borderRadius: 6,
      }}
      onClick={() => navigate('/help')}
    >
      Get Help
    </Button>
  );
}

HelpButton.propTypes = {
  width: PropTypes.string,
  marginTop: PropTypes.string,
};
