import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Context } from 'Store';
import mixpanel from "mixpanel-browser"

import GridItem from 'components/Grid/GridItem.js';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationSettingsIcon from '@mui/icons-material/NotificationsActive';
import GavelIcon from '@mui/icons-material/Gavel';
import PolicyIcon from '@mui/icons-material/Policy';
import InfoIcon from '@mui/icons-material/Info';
import {
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    color: '#324152',
    marginTop: 20,
    marginBottom: 80,
  },
  tabStyle: { color: '#324152', marginTop: 20 },
}));

function Settings() {
  let navigate = useNavigate();
  const classes = useStyles();
  const { logout } = useAuth0();
  const { state } = React.useContext(Context);
  const { user } = state;
  const showTransactions = !user.is_free_parking || (user.transaction_count && user.transaction_count > 0);

  React.useEffect(() => {
    mixpanel.track('MENU');
  }, []);

  const handleSave = (prop) => {
    navigate(prop);
  };

  const handleLogout = () => {
    mixpanel.track('LOGOUT');
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });
  }

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography variant='h5' style={{ color: '#324152' }}>
          MENU
        </Typography>
      </GridItem>

      <GridItem xs={12} sm={12} md={8} align='center'>
        <div className={classes.root}>
          <List>
            <ListItemButton onClick={() => handleSave('/profile')}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary='User Profile' />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => handleSave('/vehicles/manage')}>
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary='Manage Vehicle' />
            </ListItemButton>
            <Divider />
            {!!showTransactions &&
              <>
                <ListItemButton onClick={() => handleSave('/transactions')}>
                  <ListItemIcon>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText primary='Payment History' />
                </ListItemButton>
                <Divider />
              </>
            }
            <ListItemButton onClick={() => handleSave('/notifications-settings')}>
              <ListItemIcon>
                <NotificationSettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Notification Settings' />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => handleSave('/terms-of-use')}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary='Terms of Use' />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => handleSave('/privacy-policy')}>
              <ListItemIcon>
                <PolicyIcon />
              </ListItemIcon>
              <ListItemText primary='Privacy Policy' />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => handleSave('/about')}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary='About' />
            </ListItemButton>
            <Divider />
            <ListItemButton
              onClick={() => {
                handleLogout();
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItemButton>
          </List>
          <Divider />
        </div>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default Settings;
