import Axios from 'axios';

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getTransactions = async () => {
  try {
    let cancel;
    const response = await api.get('/transactions', {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getSubscriptionTransactions = async () => {
  try {
    let cancel;
    const response = await api.get('/subscription-transactions', {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getInvoiceLink = async (data) => {
  try {
    let cancel;
    const response = await api.get(`/stripe-invoice/${data.stripe_payment_intent_id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getSubscriptionInvoiceLink = async (data) => {
  try {
    let cancel;
    const response = await api.get(`/subscription-invoice-link/${data.stripe_subscription_id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const PaymentService = {
  init,
  getTransactions,
  getSubscriptionTransactions,
  getSubscriptionInvoiceLink,
  getInvoiceLink,
};

export default PaymentService;
