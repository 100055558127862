import React, { useEffect, useState, useContext } from 'react';
import { getConfig } from 'config';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import inputStyles from '../assets/jss/material-kit-react/components/customInputStyle';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from 'components/Grid/GridItem.js';
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SafetyChecklist, {ParkingChecklist} from './Components/SafetyChecklist';

import useAPI from 'useAPI';
import { useNavigate, useLocation } from 'react-router-dom';
import { Context, UserLocationContext } from 'Store';
import types from 'Reducer/types';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import Loading from 'components/Loading';
import { useGeolocation } from './Components/GeolocationContext';
import ScanQRDialog from './Components/ScanQRDialog';
import ShowQRDialog from "./Components/ShowQRDialog"
import ParkingSessionService from '../services/ParkingSessionService';
import VehicleService from '../services/VehicleService';
import mixpanel from "mixpanel-browser"
import UserService from "../services/UserService"
import GenerateAlert from './Components/PhoneAlert';
import LocationService from "../services/LocationService"
import HelpButton from './Components/HelpButton';

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...inputStyles,
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  parkButton: {
    marginTop: 20,
    color: 'white',
    fontSize:20,
    lineHeight: '1.2em',
    backgroundColor: '#009900',
    '&:hover': {
      backgroundColor: '#008000',
    },
    '&:active': {
      backgroundColor: '#008000',
    },
  },
  secondaryButton: { marginTop: 20, color: 'white', fontSize:20, lineHeight: '1.2em' },
  center: {textAlign: 'center', alignContent: 'center'},
  qrDialog: {textAlign: 'center', alignContent: 'center',
    width: '90%',
    maxWidth: '90%',
    margin: 0,
  },
  dialogTitle: {lineHeight: '1.2em'},
  carouselAssignment: {
    fontSize: 16,
    color: 'black',
    fontWeight: 600,
  },
  driveOnText: {
    fontSize: 16,
    color: 'black',
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
  },
  driveOnWarning: {
    fontSize: 16,
    color: 'red',
    fontWeight: 400,
    border: '2px solid red',
    marginTop: 20,
    whiteSpace: 'pre-wrap',
    padding: 5,
    lineHeight: '1.2em'
  }
}));

function getDefaultOrFirstApprovedVehicle(vehicles) {
  return vehicles.find((el) => el.is_default && el.is_approved) || vehicles.find((el) => el.is_approved) || vehicles[0];
}

function hasAnyUnapprovedVehicles(vehicles) {
  return !!vehicles.find((el) => !el.is_approved);
}

function haveVehicleApprovalsChanged(vehicles1, vehicles2) {
  if (vehicles1.length !== vehicles2.length) {
    return true;
  }
  for (const oldVeh of vehicles1) {
    const newVeh = vehicles2.find((v) => v.id === oldVeh.id);
    if (!newVeh || oldVeh.is_approved !== newVeh.is_approved) {
      return true;
    }
  }
  return false;
}

function SelectVehicle() {
  const navigate = useNavigate();
  const classes = useStyles();
  const api = useAPI();
  const config = getConfig();
  const custServiceNumber = config.custServiceNumber;
  const [isFromPark, setIsFromPark] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { userLocation } = useContext(UserLocationContext);
  const { state, dispatch } = useContext(Context);
  const { user, vehicles = [], parkingSession } = state;
  const [selectedVehicle, setSelectedVehicle] = useState(vehicles.length > 0 ? getDefaultOrFirstApprovedVehicle(vehicles) : '');
  const [assignmentError, setAssignmentError] = useState(null);
  const [selectedCarousel, setSelectedCarousel] = useState(null);
  const [surfaceParking, setSurfaceParking] = useState(false);
  const [isScanPrintedQRCode, setScanPrintedQRCode] = useState(false);
  const [parkingToken, setParkingToken] = useState('');
  const [isShowParkingToken, setShowParkingToken] = useState(false);
  const { active: geolocationActive, latitude, longitude, feetFrom } = useGeolocation();
  const [geoPark, setGeoPark] = useState(false);
  const [parkingOptions, setParkingOptions] = useState(null);
  const [isValetParking, setValetParking] = useState(false);
  const [showSafetyChecklist, setShowSafetyChcklist] = useState(false);

  useEffect(() => {
    VehicleService.init(api);
    ParkingSessionService.init(api);
    UserService.init(api);
    if (location.pathname.includes('park')) {
      setIsFromPark(true);
      if (ParkingSessionService.isParked(parkingSession) || !userLocation) {
        navigate('/');
        return;
      }
    }
    fetchVehicles();
  }, [api]);

  useEffect(() => {
    if (api && userLocation) {
      ParkingSessionService.getParkingOptions(userLocation).then(options => {
        setParkingOptions(options);
      })
    }
  }, [userLocation, api]);

  const fetchVehicles = async () => {
    const res = await VehicleService.getVehicles();
    dispatch({ type: types.SET_VEHICLES, payload: res });
    if (res.length === 0) {
      const newPath = location.pathname.includes('park') ? '/park/vehicle/new' : '/vehicles/new';
      navigate(newPath);
      return;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!selectedVehicle && vehicles.length > 0) {
      setSelectedVehicle(getDefaultOrFirstApprovedVehicle(vehicles));
    } else if (selectedVehicle && vehicles) {
      const currentVersion = vehicles.find((v) => v.id === selectedVehicle.id);
      if (currentVersion && currentVersion.is_approved !== selectedVehicle.is_approved) {
        setSelectedVehicle(currentVersion);
      }
    }

    let vehicleApprovalTimer = null;
    if (hasAnyUnapprovedVehicles(vehicles)) {
      vehicleApprovalTimer = setInterval(function () {
        checkForVehicleApproval(vehicles);
      }, 5000);
    }
    return () => {
      vehicleApprovalTimer && clearInterval(vehicleApprovalTimer);
    }
  }, [vehicles]);

  const checkForVehicleApproval = async (vehicles) => {
    const res = await VehicleService.getVehicles();
    if (haveVehicleApprovalsChanged(vehicles, res)) {
      dispatch({ type: types.SET_VEHICLES, payload: res });
    }
  };

  useEffect(() => {
    maybeSelectCarousel();
  }, [selectedVehicle, parkingOptions]);

  const maybeSelectCarousel = () => {
    setAssignmentError(null);
    setSelectedCarousel(null);
    if (selectedVehicle && parkingOptions) {
      setValetParking(!!parkingOptions.isValetOnly); // default setting

      if (!selectedVehicle.is_approved) {
        setAssignmentError('Vehicle must be approved before parking.');
        return;
      }

      const vehicleOptions = parkingOptions.vehicles.find(v => v.id === selectedVehicle.id);
      if (vehicleOptions.parkingSession) {
        setAssignmentError(`Vehicle is currently parked at ${vehicleOptions.parkingSession.location.name}`);
        return;
      }

      if (parkingOptions.activeReservation) {
        const carouselName = parkingOptions.activeReservation.carousel.nickname;
        if (vehicleOptions.isReservationSizeMismatch) {
          const message = <span>
            You have a reservation on {carouselName}, but this vehicle is too big.
            Please contact customer service at <a href={`tel:${custServiceNumber}`} style={{ color: 'red', textDecoration: 'underline' }}>{custServiceNumber}</a>.
          </span>;
          setAssignmentError(message);
          return;
        }

        const reservedCarousel = parkingOptions.carousels.find(c => c.id === parkingOptions.activeReservation.carousel_id);
        if (reservedCarousel.availableSpaces === 0) {
          setAssignmentError(`You have a reservation on ${reservedCarousel.nickname}, but the carousel is unexpectedly full.\nPlease contact us.`)
          return;
        }

        setSelectedCarousel(reservedCarousel);
        if (reservedCarousel.isValet) {
          setValetParking(true);
        }
        return;
      }

      if (vehicleOptions.isCarouselAssignment || vehicleOptions.isAdHoc) {
        const assignedCarousel = parkingOptions.carousels.find(c => c.id === vehicleOptions.carouselId);
        if (assignedCarousel) {
          setSelectedCarousel(assignedCarousel);
          if (assignedCarousel.isValet) {
            setValetParking(true);
          }
        } else {
          // Ad-hoc surface parking
          setSurfaceParking(true);
        }
      } else {
        setAssignmentError('No spots available for this vehicle');
      }
    }
  }

  const onNewVehicle = () => {
    mixpanel.track('ADD_VEHICLE');
    if (isFromPark) {
      navigate('/park/vehicle/new');
    } else {
      navigate('/vehicles/new');
    }
  };

  const onReadyToPark = async () => {
    try {
      if (isValetParking) {
        const token = await ParkingSessionService.getParkingTokenForValetParking(selectedVehicle.id, userLocation.id);
        mixpanel.track('SHOW_QR_TO_PARK');
        setParkingToken(token);
        setShowParkingToken(true);
      } else if (selectedCarousel) {
        if (!selectedCarousel.hasGate && LocationService.locationHasQRReader(userLocation)) {
          setShowSafetyChcklist(true);
        } else if (geoPark) {
          mixpanel.track('GEO_PARK');
          await ParkingSessionService.geoPark(selectedCarousel.id, selectedVehicle.id, latitude, longitude);
          navigate('/');
        } else {
          if (LocationService.locationHasQRReader(userLocation)) {
            // STK-283: Specify any assigned or selected carousel in the token
            const token = await ParkingSessionService.getParkingToken({vehicle: selectedVehicle, action: 'PARK', carousel: selectedCarousel, location: userLocation});
            mixpanel.track('SHOW_QR_TO_PARK');
            setParkingToken(token);
            setShowParkingToken(true);
          } else {
            mixpanel.track('OPEN_QR_TO_PARK');
            setScanPrintedQRCode(true);
          }
        }
      } else if (surfaceParking) {
        selfServeSurfaceParkingWarning();
      }
    } catch (err) {
      alert(err);
      navigate(0);
    }
  };

  const selfServeSurfaceParkingWarning = () => {
    console.warn("Surface parking available but this app doesn't handle self-serve surface parking yet.")
    alert('Self-serve surface parking not supported.');
  }

  const onScanQRForStowing = () => {
    ParkingSessionService.getParkingToken({vehicle: selectedVehicle, action: 'PARK', carousel: selectedCarousel, location: userLocation}).then((token) => {
      mixpanel.track('SHOW_QR_TO_PARK');
      setShowSafetyChcklist(false);
      setParkingToken(token);
      setShowParkingToken(true);
    });
  }

  const onCancelStowing = () => {
    setShowSafetyChcklist(false);
  }

  const onCancelShowParkingToken = () => {
    setParkingToken('');
    setShowParkingToken(false);
  };

  const checkParkingTokenStatus = () => {
    ParkingSessionService.getParkingTokenStatus(parkingToken)
      .then((status) => {
        if (status) {
          GenerateAlert();
          setTimeout(()=>navigate('/'), 500); // leave time for alert
        }
      })
      .catch((e) => {
        GenerateAlert();
        onCancelShowParkingToken();
        setTimeout(()=>alert(e), 500); // leave time for alert
      });
  };

  useEffect(() => {
    let tokenStatusTimer = null;
    if (isShowParkingToken) {
      tokenStatusTimer = setInterval(function () {
        checkParkingTokenStatus();
      }, 2000);
    }
    return () => {
      tokenStatusTimer && clearInterval(tokenStatusTimer);
    }
  }, [isShowParkingToken]);

  /**
   * Geo-location effects
   */
  useEffect(() => {
    if (!geolocationActive ||
      (userLocation && !userLocation.is_park_with_geolocation) ||
      ('is_allow_geoparking' in user && !user.is_allow_geoparking) ||
      !selectedCarousel
    ) {
      setGeoPark(false);
    } else if (userLocation) {
      const feetFromLocation = feetFrom(userLocation);
      if (feetFromLocation > userLocation.parking_radius_ft) {
        setGeoPark(false);
      } else {
        setGeoPark(true);
      }
    }
  }, [userLocation, geolocationActive, latitude, longitude, selectedCarousel]);

  const handleQRScanned = (token) => {
    mixpanel.track('SCAN_QR_TO_PARK');
    setScanPrintedQRCode(false);
    ParkingSessionService.getCarouselSlot(token, userLocation.id, selectedVehicle.id).then(() => {
      navigate('/');
    }).catch((err) => {
      alert(err);
    });
  }

  const onEditVehicle = () => {
    if (selectedVehicle) {
      mixpanel.track('EDIT_VEHICLE');
      navigate('/vehicles/' + selectedVehicle.id + '/edit');
    }
  };

  const onViewVehiclePhotos = () => {
    if (selectedVehicle) {
      mixpanel.track('VIEW_VEHICLE_PHOTOS');
      navigate(`/vehicles/${selectedVehicle.id}/photos`);
    }
  };

  const getCarouselText = () => {
    if (assignmentError) {
      return <Typography style={{ fontSize: 16, color: 'red' }}>
        {assignmentError}
      </Typography>
    }
    if (isValetParking) {
      return <Typography style={{ fontSize: 18, color: 'black' }}>
        Please check-in with a valet.
      </Typography>
    }

    if (selectedCarousel) {
      let addlText = '';
      if (!selectedCarousel.hasGate) {
        addlText = selectedCarousel.isBlocked ?
          <div className={classes.driveOnWarning}>
            {selectedCarousel.nickname} is currently in use. Please wait for the light to stop flashing and any parking operation to complete before driving on.
          </div> :
          <div className={classes.driveOnText}>
            Please drive onto {selectedCarousel.nickname} if clear to do so.
          </div>;
      }
      return <>
        <div className={classes.carouselAssignment}>
          You have been assigned to {selectedCarousel.nickname}.
        </div>
        {addlText}
      </>
    } else if (surfaceParking) {
      return <Typography style={{ fontSize: 18, color: 'black' }}>
        Self-serve surface parking not supported.
      </Typography>
    }

    return <Typography style={{ fontSize: 16, color: 'black' }}>
      No available carousels
    </Typography>
  }

  const getParkingButtonText = () => {
    if (assignmentError) {
      return 'Unable to Park';
    }
    if (isValetParking) {
      return 'Click for Valet Check-In Code';
    }
    if (selectedCarousel) {
      if (selectedCarousel.hasGate) {
        return geoPark ? `Click when directly in front of ${selectedCarousel.nickname}` : `Drive to ${selectedCarousel.nickname} and Scan QR Code`;
      } else {
        return 'Click Once Parked';
      }
    } else if (surfaceParking) {
      return "Self-serve surface parking not supported."
    }
    return <Typography variant='body1' color='primary'>
      No available carousels
    </Typography>
  }

  const getShowQRText = () => {
    if (isValetParking) {
      return 'Show QR code to valet';
    }
    if (selectedCarousel) {
      return `Hold QR code up to reader at ${selectedCarousel.nickname}`;
    } else if (surfaceParking) {
      return 'Show QR code to valet';
    } else {
      return 'Hold QR code up to reader at the carousel';
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  const isInParkingFlow = () => {
    return isFromPark && (selectedCarousel || surfaceParking || isValetParking);
  }

  return (
    <AuthenticatedContainer>
      <SafetyChecklist
          isOpen={showSafetyChecklist}
          checklist={ParkingChecklist}
          stowFn={onScanQRForStowing}
          stowText={'Scan QR Code to Stow Vehicle'}
          cancelFn={onCancelStowing}
          cancelText={'Cancel'}
        />
      <GridItem xs={12} sm={12} md={8} align='center' style={{ padding: 10 }}>
        <Typography variant='h5' color='primary'>
          {isFromPark ? userLocation.name : 'Manage Vehicle'}
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Box px={3} py={2}>
          <FormControl fullWidth margin='dense'>
            <InputLabel>Select vehicle</InputLabel>
            <Select
              value={selectedVehicle?.id || ''}
              onChange={(e) => {
                setSelectedVehicle(vehicles.find(v => v.id === e.target.value));
              }}
              label='Select vehicle'
            >
              {vehicles.map((vehicle) => (
                <MenuItem
                  value={vehicle.id}
                  key={vehicle.id}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  {VehicleService.getVehicleLabel(vehicle)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {isFromPark &&
          <Box px={2}>
            {getCarouselText()}
          </Box>
        }
        <Box pd={3} px={3}>
          {isInParkingFlow()
            ? (isScanPrintedQRCode
              ? <ScanQRDialog classes={classes} isOpen={isScanPrintedQRCode} title="Scan QR Code on Carousel" onScan={handleQRScanned} onCancel={()=>setScanPrintedQRCode(false)} />
              : (isShowParkingToken
                  ? <ShowQRDialog classes={classes} isOpen={isShowParkingToken} title={getShowQRText()} code={parkingToken} onCancel={onCancelShowParkingToken} />
                  : <Button
                    onClick={onReadyToPark}
                    color='info'
                    variant='contained'
                    fullWidth
                    className={classes.parkButton}
                  >
                    {getParkingButtonText()}
                  </Button>
              ))
            : <>
              <Button
                onClick={onEditVehicle}
                color='primary'
                variant='contained'
                fullWidth
                className={classes.secondaryButton}
                disabled={!selectedVehicle}
              >
                Edit Selected Vehicle
              </Button>
              {config.enableVehiclePhotos &&
                <Button
                  onClick={onViewVehiclePhotos}
                  color='success'
                  variant='contained'
                  fullWidth
                  className={classes.secondaryButton}
                  disabled={!selectedVehicle}
                >
                  View Vehicle Photos
                </Button>}
            </>
          }
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center' style={{ color: 'black', marginTop: 20 }}>
        OR
      </GridItem>

      <GridItem xs={12} sm={12} md={8} align='center'>
        <Box p={2}>
          <Button
            onClick={onNewVehicle}
            color='primary'
            variant='contained'
            fullWidth
            className={classes.secondaryButton}
          >
            Add New Vehicle
          </Button>
        </Box>
      </GridItem>

      <GridItem xs={12} sm={12} md={8} align='center'>
        <Box p={1}>
          <HelpButton width={'96%'} />
        </Box>
      </GridItem>

    </AuthenticatedContainer>
  );
}

export default SelectVehicle;

