import React, { useEffect, useContext, useState } from 'react';

import GridItem from 'components/Grid/GridItem.js';
import { Button } from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import CircularProgress from '@mui/material/CircularProgress';
import UserService from '../../services/UserService';
import makeStyles from '@mui/styles/makeStyles';
import { Context } from 'Store';
import types from 'Reducer/types';
import useAPI from 'useAPI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TermsOfUse from './TermsOfUseContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import GridContainer from 'components/Grid/GridContainer.js';
import mixpanel from "mixpanel-browser"

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
export default function TNCDialogBox() {
  const classes = useStyles();
  const api = useAPI();
  const { state, dispatch } = useContext(Context);
  const { tncChange, user } = state;
  const [step, setStep] = useState(1);
  const [saving, setSaving] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState(true);

  useEffect(() => {
    UserService.init(api);
  }, [api]);

  useEffect(() => {
    if (user && !user.is_new) {
      setStep(2);
    }
  }, [user]);

  function isUserNeedToAgree() {
    // If User is initialized and not agreed
    return Object.keys(user).length > 0 && !user.is_agree;
  }

  function isShowRegistrationSuccessful() {
    return isUserNeedToAgree() && step === 1;
  }

  const gotoStep2 = () => {
    mixpanel.track('REGISTRATION_SUCCESSFUL');
    setStep(2);
  }

  function isShowTermsAndConditions() {
    return isUserNeedToAgree() && step === 2;
  }

  const handleAgreeToTermsAndConditions = (agree) => {
    mixpanel.track(agree ? 'TNC_AGREE' : 'TNC_DISAGREE')
    setSaving(true);
    UserService.updateTermsAndConditions(user, agree).then((user) => {
      dispatch({ type: types.SET_USER, payload: user });
      dispatch({
        type: types.SET_TNC_CHANGE,
        payload: !tncChange,
      });
    });
    setSaving(false);
  };

  const scrollTracker = () => {
    const content = document.getElementById('tnc-content');
    const contentBottom = content.getBoundingClientRect().bottom;
    const contentBox = document.getElementById('tnc-window');
    const contentBoxBottom = contentBox.getBoundingClientRect().bottom;
    // A hack: we know that the bottom of the tnc-window is 30px below the bottom of the tnc-content
    const isAtBottom = contentBottom <= (contentBoxBottom + 10); // give it 40px leeway since Android was not triggering
    if (isAtBottom) {
      setCheckboxDisabled(false);
    }
  }

  return (
    <AuthenticatedContainer>
        <GridItem xs={12} sm={12} md={8} align='center'>
          {/* Step 1 - Thanks for registering */}
          <Dialog
            open={isShowRegistrationSuccessful()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Registration Successful'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Thank you for registering. Please review our Terms of Use.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={gotoStep2}
                color='primary'
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>

          {/* Step 2 - T&Cs */}
          <Dialog
            open={isShowTermsAndConditions()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogContent onScroll={scrollTracker} id='tnc-window' style={{padding: 0}}>
              <TermsOfUse />
            </DialogContent>
            <DialogActions>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    style={{color: checkboxDisabled ? 'ltgrey' : 'black'}}
                    control={
                      <Checkbox
                        checked={!!checked}
                        onChange={(event) => {
                          setChecked(event.target.checked);
                        }}
                        color='primary'
                        disabled={checkboxDisabled}
                      />
                    }
                    label='I have read the Terms of Use'
                  />
                </GridItem>
                <GridItem item xs={12} sm={12} md={12}>
                  <div>
                    <Button
                      onClick={() => {
                        handleAgreeToTermsAndConditions(true);
                      }}
                      color='primary'
                      autoFocus
                      disabled={!checked}
                      className='float-right'
                    >
                      Agree
                    </Button>
                    {saving && <CircularProgress size={14} className={classes.buttonProgress} />}
                  </div>
                  <Button
                    onClick={() => {
                      handleAgreeToTermsAndConditions(false);
                    }}
                    disabled={!checked}
                    className='float-right'
                  >
                    Disagree
                  </Button>
                </GridItem>
              </GridContainer>
            </DialogActions>
          </Dialog>
        </GridItem>
    </AuthenticatedContainer>
  );
}
