import React, { useEffect, useState, useContext } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import GridItem from 'components/Grid/GridItem.js';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import {
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material';
import NotificationService from '../../services/NotificationService';
import useAPI from 'useAPI';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import mixpanel from "mixpanel-browser"
import { Context } from 'Store';


React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

function NotificationSettings() {
  let navigate = useNavigate();
  const api = useAPI();
  const [showUpdatedMessage, setShowUpdatedMessage] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [values, setValues] = useState({
    app_notification: true,
    sms: true,
  });
  const { state } = useContext(Context);
  const { user } = state;
  const showUnverifiedPhoneWarning = !user?.phone_verified;

  useEffect(() => {
    NotificationService.init(api);
    fetchData();
    mixpanel.track('NOTIFICATION_SETTINGS');
  }, [api]);

  const fetchData = async () => {
    try {
      const res = await NotificationService.getNotificationStatus();
      if (res) {
        const { app_notification, sms } = res;
        setValues({ app_notification, sms });
        setInitialValues({ app_notification, sms });
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleChange = async (prop, checked) => {
    setValues({ ...values, [prop]: checked });
  };

  const isFirstSelection = () => {
    return !initialValues;
  }

  const isChanged = () => {
    return !!initialValues &&
      (!!values.app_notification !== !!initialValues.app_notification || !!values.sms !== !!initialValues.sms);
  }

  const enableSave = () => {
    return isFirstSelection() || isChanged();
  }

  const saveButtonText = () => {
    return isFirstSelection() ? 'Save Selections' : 'Save Changes';
  }

  const showSkipOption = () => {
    return !isFirstSelection();
  }

  const goHome = (withDelay = false) => {
    if (withDelay) {
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } else {
      navigate('/');
    }
  }

  const handleSave = async () => {
    mixpanel.track('CHANGE_NOTIFICATION_SETTINGS');
    NotificationService.updateNotificationStatus(values).then(() => {
      setShowUpdatedMessage(true);
    });
  };

  const handleSkip = async () => {
    mixpanel.track('SKIP_NOTIFICATION_SETTINGS');
    goHome();
  };

  const handleClose = () => {
    setShowUpdatedMessage(false);
    goHome(true);
  };

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center' style={{ marginBottom: '3%' }}>
        <Typography
          variant='h6'
          style={{
            padding: 15,
            color: 'black',
          }}
        >
          Notification Settings
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center' style={{ marginBottom: '3%' }}>
        <div style={{ color: 'black' }}>
          {' '}
          <h5>Choose Notifications you want to receive</h5>
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} align='center'>
        <FormControl component='fieldset' variant='standard'>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!!values.app_notification}
                  onChange={(event) => {
                    handleChange('app_notification', event.target.checked);
                  }}
                  name='app_notification'
                  color='primary'
                />
              }
              label='App Notifications'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!!values.sms}
                  onChange={(event) => {
                    handleChange('sms', event.target.checked);
                  }}
                  name='sms'
                  color='primary'
                />
              }
              label='SMS'
            />
          </FormGroup>
        </FormControl>
        {showUnverifiedPhoneWarning &&
        <div style={{color: 'red'}}>You will not receive SMS notifications unless your phone number is verified on the User Profile screen.</div>
        }
      </GridItem>
      {showSkipOption() && (
        <>
          <GridItem
            xs={2}
            sm={2}
            md={1}
            align='center'
            style={{ marginTop: '5%', marginRight: '2%' }}
          >
            <Button
              onClick={() => handleSkip()}
              variant='contained'
              fullWidth
            >
              Skip
            </Button>
          </GridItem>
        </>
      )}

      <GridItem xs={12} sm={12} md={8} align='center' style={{ marginTop: '5%' }}>
        <Button onClick={() => handleSave()} variant='contained' color='primary' fullWidth disabled={!enableSave()}>
          {saveButtonText()}
        </Button>
      </GridItem>
      <div>
        <Snackbar
          open={showUpdatedMessage}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity='success' onClose={handleClose}>
            Notification settings updated
          </Alert>
        </Snackbar>
      </div>
    </AuthenticatedContainer>
  );
}

export default NotificationSettings;
