import React from 'react';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import Button from 'components/CustomButtons/Button.js';
import Snackbar from '@mui/material/Snackbar';

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
}));

function Retrieve() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <AuthenticatedContainer>
      <>
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Typography variant='h4' color='secondary'>
            Retrieving Vehicle
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box p={5}>
            <Typography variant='subtitle1' color='secondary'>
              Your vehicle will be available in location one
            </Typography>
          </Box>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} align='center'>
          <List className={classes.root} subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader>{`Before you depart remember to:`}</ListSubheader>
                <ListItem key={`item-one`}>
                  <ListItemText primary={`Item One`} style={{ color: 'black' }} />
                </ListItem>
                <ListItem key={`item-two`}>
                  <ListItemText primary={`Item two`} style={{ color: 'black' }} />
                </ListItem>
                <ListItem key={`item-Three`}>
                  <ListItemText primary={`Item Three`} style={{ color: 'black' }} />
                </ListItem>
              </ul>
            </li>
          </List>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box mt={5}>
            <Button color='primary' size='lg' onClick={handleClick}>
              Confirm Departure
            </Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <h6 style={{ color: 'black' }}>
                Departure Confirmation will automatically occur in 15 minutes.
              </h6>
            </Snackbar>
          </Box>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}

export default Retrieve;
