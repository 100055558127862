import React from 'react';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Typography } from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import VehicleForm from './VehicleForm';
import { useParams } from 'react-router-dom';

function EditVehicle() {
  let { id } = useParams();
  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography variant='h5' style={{ color: '#324152' }}>
          Edit Vehicle
        </Typography>
      </GridItem>
      <VehicleForm id={id} />
    </AuthenticatedContainer>
  );
}

export default EditVehicle;
