import React from 'react';
import './Policies.css'

const RemoteLink = (href, text) => {
  return <a target={'_blank'} rel={'noreferrer'} href={href}>{text || href}</a>
}
const PrivacyPolicy = RemoteLink('/privacy-policy', 'Privacy Policy');
const EmailUs = <a href="mailto:info@stakmobility.com">info@stakmobility.com</a>;

export default function TermsOfUseContent() {
  return (
    <div id='tnc-content' className={'policy'}>
      <h1>Terms of Use</h1>
      <p>Last Modified: February 2023</p>
      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>I.</span>
          <span className={'policy-section-title'}>Acceptance of the Terms of Use</span>
        </h2>
        <p>Welcome to the website of Stak Corp. (<b>&quot;Company&quot;</b>, <b>&quot;we&quot;</b>, <b>&quot;our&quot;</b> or <b>&quot;us&quot;</b>).
          The following terms and conditions, together with any documents they expressly incorporate by reference
          (collectively, these <b>&quot;Terms of Use&quot;</b>), govern your access to and use of the website
          <a target={'_blank'} rel={'noreferrer'} href={'https://www.stakmobility.com'}>https://www.stakmobility.com</a>, its microsites,
          mobile websites, mobile applications, and your use of interactive features, widgets, plug-ins, content, products,
          assets, downloads and/or services that we own and control or that post a link to these Terms of Use (collectively,
          the <b>&quot;Website&quot;</b>), whether as a guest or a registered user.</p>
        <p>Please read the Terms of Use carefully before you start to use the Website.&#160;
          <b>By using the Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy,
            found <a target={'_blank'} rel={'noreferrer'} href={'/privacy-policy'}>here</a>,
            incorporated herein by reference.</b> If you do not agree to these Terms of Use or the Privacy Policy, you must
          not access or use the Website. </p>
        <p>This Website is offered and available to users who are 18 years of age or older and reside in the United States or
          any of its territories or possessions. By using this Website, you represent and warrant that you are of legal age
          to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet
          all of these requirements, you must not access or use the Website.</p>
        <p><b>IMPORTANT NOTICE:</b> Your use of the Website or our services are subject to an arbitration provision in Section 11,
          requiring any claim or dispute with Company to be resolved by way of binding arbitration. By agreeing to these terms,
          you and Company waive any right to participate in a class-action lawsuit or class-wide arbitration.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>II.</span>
          <span className={'policy-section-title'}>Changes to the Terms of Use</span>
        </h2>
        <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective
          immediately when we post them, and apply to all access to and use of the Website thereafter.</p>
        <p>Your continued use of the Website following the posting of revised Terms of Use or Privacy Policy means that you
          accept and agree to the changes. You are expected to check this page each time you access or use this Website so
          you are aware of any changes, as they are binding on you.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>III.</span>
          <span className={'policy-section-title'}>Accessing the Website and Account Security</span>
        </h2>
        <p>We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</p>
        <p>You are responsible for:</p>
        <div>
          <ul>
            <li>Making all arrangements necessary for you to have access to the Website.</li>
            <li>Ensuring that all persons who access the Website through your internet connection or on your behalf are aware of these Terms of Use and comply with them.</li>
          </ul>
        </div>
        <p>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or
          other information. It is a condition of your use of the Website that all the information you provide on the Website
          is correct, current and complete. You agree that all information you provide to register with this Website or otherwise,
          including but not limited to through the use of any interactive features on the Website, is governed by our {PrivacyPolicy}, and you
          consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>
        <p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
        <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>IV.</span>
          <span className={'policy-section-title'}>Intellectual Property Rights</span>
        </h2>
        <p>The Website and its entire contents, features and functionality (including but not limited to all information, software,
          text, trademarks, logos, displays, images, video and audio, and the design, selection and arrangement thereof), are owned
          by the Company, its licensors or other providers of such material and are protected by United States and international
          copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. These Terms of Use
          permit you to use the Website for your personal, non-commercial use only. You must not copy, mirror, frame, reproduce,
          distribute, modify, create derivative works of, translate, publicly display, publicly perform, republish, download, store or
          transmit any of the material on our Website, in whole or in part, or in any way without our prior written consent, except as
          follows:</p>
        <div>
          <ul>
            <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
            <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
            <li>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication or distribution.</li>
            <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
            <li>If we provide social media features with certain content, you may take such actions as are enabled by such features.</li>
          </ul>
        </div>
        <div>
          <b>You must not:</b>
          <ul>
            <li>Modify copies of any materials from this Website.</li>
            <li>Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</li>
            <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Website.</li>
          </ul>
        </div>
        <p>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</p>
        <p>If you wish to make any use of material on the Website other than that set out in this section, please address your request to: {EmailUs}.</p>
        <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
        <h3>Trademarks</h3>
        <p>Our Company name, our logo, and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>V.</span>
          <span className={'policy-section-title'}>Prohibited Uses</span>
        </h2>
        <p>You agree not to access or use the Website for any unlawful purpose or in violation of these Terms of Use, including without limitation, to:</p>
        <p>(a)	transmit any information or message that contains unlawful, infringing, threatening, fraudulent, libelous, defamatory, obscene or abusive information or language;</p>
        <p>(b)	falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</p>
        <p>(c)	falsely state or misrepresent your affiliation with any person or entity, including the origin of any information you provide;</p>
        <p>(d)	reproduce, duplicate, copy, download, store, further transmit, disseminate, distribute, transfer, or otherwise exploit the Website, or any portion thereof without the prior written consent of Company, except as expressly permitted herein, and is not used or shared in any way that is commercially prejudicial to or competitive with Company;</p>
        <p>(e)	violate any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries);</p>
        <p>(f)	send, knowingly receive, upload, download, use or re-use any material which does not comply with these Terms of Use;</p>
        <p>(g)	transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any &quot;junk mail&quot;, &quot;chain letter&quot; or &quot;spam&quot; or any other similar solicitation;</p>
        <p>(h)	impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing);</p>
        <p>(i)	engage in any other conduct that restricts or inhibits anyone&apos;s use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability;</p>
        <p>Additionally, <b><u>you agree not to</u></b>:</p>
        <p>(j)	use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party&apos;s use of the Website, including their ability to engage in real time activities through the Website;</p>
        <p>(k)	use any robot, spider, intelligent agent, or other automatic device, process or means to search, monitor, or copy the Website, or the reports, data, information, content, software, products, services, or other materials thereon, generated by or obtained from the Website or any other unauthorized means to compile or access the Website for any purpose without Company’s permission, with the exception of generally available third-party web browsers;.</p>
        <p>(l)	use any device, software or routine that interferes with the proper working of the Website, or attempt to probe, scan, test the vulnerability of, or breach the security of the Company system or network;</p>
        <p>(m)	introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful;</p>
        <p>(n)	attempt to gain unauthorized access to, overburden, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website;</p>
        <p>(o)	 attack the Website via a denial-of-service attack or a distributed denial-of-service attack;</p>
        <p>(p)	otherwise attempt to interfere with the proper working of the Website; or interfere with or attempt to interfere with any software making up a part of the Website, by way of circumvention, reverse engineering, deciphering, decompiling disassembling, decrypting, or otherwise.</p>
        <p><b>YOU ALSO AGREE NOT TO ENGAGE IN ANY RECKLESS BEHAVIOR WHILE USING OR ACCESSING THE WEBSITE, INCLUDING WITHOUT LIMITATION, WHILE UNDER THE INFLUENCE OF ALCOHOL, DRUGS, SUBSTANCE, OR ANY OTHER CONDITIONS THAT WOULD REASONABLY POSE A HARM TO THE SAFETY OF YOURSELF OR OTHERS. YOU ACKNOWLEDGE AND AGREE THAT STAK CORP. IS NOT RESPONSIBLE FOR YOUR CONDUCT WHILE USING OR ACCESSING THE WEBSITE AND YOUR FAILURE TO COMPLY WITH THESE TERMS OF USE IS AT YOUR OWN RISK AND THAT STAK CORP SHALL HAVE NO LIABILITY ARISING FROM YOUR FAILURE TO COMPLY WITH THESE TERMS OF USE.</b></p>
        <p>Violation of system or network security and certain other conduct may result in civil or criminal liability. We may investigate and work with law enforcement authorities to prosecute users who violate these Terms of Use. We may suspend or terminate your access to the Website for any or no reason at any time without notice. </p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>VI.</span>
          <span className={'policy-section-title'}>Changes to the Website</span>
        </h2>
        <p>We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>VII.</span>
          <span className={'policy-section-title'}>Information About You and Your Visits to the Website</span>
        </h2>
        <p>All information we collect on this Website is subject to our {PrivacyPolicy}. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>VIII.</span>
          <span className={'policy-section-title'}>Other Terms and Conditions</span>
        </h2>
        <p>Additional terms and conditions may also apply to specific portions, services or features of the Website, including without limitation, the SaaS Services Agreement (the “SAAS Agreement”). All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use, as applicable. In the event of a conflict between these Terms of Use and the SAAS Agreement, the latter shall govern.</p>
      </div>
      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>IX.</span>
          <span className={'policy-section-title'}>Linking to the Website and Social Media Features</span>
        </h2>
        <p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part.</p>
        <p>This Website may provide certain social media features that enable you to:</p>
        <ul>
          <li>Link from your own or certain third-party websites to certain content on this Website.</li>
          <li>Send e-mails or other communications with certain content, or links to certain content, on this Website.</li>
          <li>Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.</li>
        </ul>
        <p>You may use these features solely as they are provided by us and solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</p>
        <ul>
          <li>Establish a link from any website that is not owned by you.</li>
          <li>Cause the Website or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.</li>
          <li>Link to any part of the Website other than the homepage.</li>
          <li>Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.</li>
        </ul>
        <p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.</p>
        <p>We may disable all or any social media features and any links at any time without notice in our discretion.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>X.</span>
          <span className={'policy-section-title'}>Third-Party Links from the Website</span>
        </h2>
        <p>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XI.</span>
          <span className={'policy-section-title'}>Geographic Restrictions</span>
        </h2>
        <p>The owner of the Website is based in the state of New York in the United States. We provide this Website for use only by persons located in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XII.</span>
          <span className={'policy-section-title'}>Disclaimer of Warranties</span>
        </h2>
        <p><b>YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, TIMELINESS, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.  TO THE FULLEST EXTENT PROVIDED BY LAW,WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</b></p>
        <p><b>TO THE FULLEST EXTENT PROVIDED BY LAW, COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR PARTICULAR PURPOSE, TITLE, SECURITY, COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE.</b></p>
        <p><b>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</b></p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XIII.</span>
          <span className={'policy-section-title'}>Limitation on Liability</span>
        </h2>
        <p><b>IN NO EVENT WILL  COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, COMPUTER AND/OR DEVICE OR TECHNOLOGY FAILURE OR MALFUNCTION, ANY ALLEGED FAILURE OR PERFORMANCE ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, OR DELAY IN SERVICE, OPERATION, OR TRANSMISSION OF THE WEBSITE, OR ANY ALLEGED TRANSMISSION OF COMPUTER VIRUS, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. IN NO EVENT SHALL COMPANY’S LIABILITY EXCEED THE AMOUNT(S) PAID FOR THE PRODUCT OR SERVICE THAT IS THE SUBJECT OF THE CLAIM.</b></p>
        <p><b>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</b></p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XIV.</span>
          <span className={'policy-section-title'}>Indemnification</span>
        </h2>
        <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys&apos; fees) arising out of or relating to your violation of these Terms of Use or your use or interaction with the Website,  or its services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Website.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XV.</span>
          <span className={'policy-section-title'}>Mandatory Arbitration of Dispute and Class Action Waiver</span>
        </h2>
        <p>All matters relating to the Website, our services, and/or these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of the State of New York without giving effect to any choice or conflict of law provision or rule (whether of the State of New York or any other jurisdiction).</p>
        <p>Any legal suit, action, or proceeding arising out of, or related to, these Terms, the Services, or the Website shall be instituted exclusively through binding arbitration under the Rules of Arbitration of the American Arbitration Association (“AAA”) applying New York law in New York County, New York. <b>ARBITRATION MEANS THAT YOU WAIVE YOUR RIGHT TO A JUDGE OR JURY IN A COURT PROCEEDING AND YOUR GROUNDS FOR APPEAL ARE LIMITED.</b></p>
        <p>In the event of a dispute, you must first send to Company, a notice of the dispute that shall include a written statement that sets forth the name, address and all relevant contact information, the facts giving rise to the dispute, and the relief requested (the “Dispute Notice”). The Dispute Notice must be addressed to Stak Corp. 431 10th Street, Brooklyn, New York 11215, Attn: Legal. If you and Company do not reach an agreement to resolve the dispute within sixty (60) days after the Dispute Notice is received, either party may commence an arbitration proceeding pursuant to this Section. Following submission and receipt of the Dispute Notice, each party agrees to act in good faith to seek to resolve the dispute before commencing arbitration.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XVI.</span>
          <span className={'policy-section-title'}>Class Action Waiver</span>
        </h2>
        <p><b>YOU EXPRESSLY WAIVE ANY ABILITY TO MAINTAIN ANY CLASS ACTION IN ANY FORUM. ANY ARBITRATION, CLAIM, OR OTHER PROCEEDINGS BETWEEN YOU AND COMPANY SHALL BE CONDUCTED ON AN INDIVIDUAL BASIS AND NOT IN ANY CLASS ACTION, MASS ACTION, OR ON A CONSOLIDATED OR REPRESENTATIVE BASIS. NEITHER PARTY WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR IN ANY OTHER PROCEEDING IN WHICH EITHER PARTY ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY. NO ARBITRATION OR OTHER PROCEEDING WILL BE COMBINED WITH ANOTHER WITHOUT THE PRIOR WRITTEN CONSENT OF ALL PARTIES TO ALL AFFECTED ARBITRATIONS OR PROCEEDINGS.</b></p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XVII.</span>
          <span className={'policy-section-title'}>Arbitration Procedure</span>
        </h2>
        <p>The arbitration will be administered by the AAA and governed by the Consumer Arbitration Rules of the AAA (“AAA Rules”) in conjunction with the rules set forth in these Terms of Use, except that AAA may not administer any multiple claimant or class arbitration, as the parties agree that the arbitration shall be limited to the resolution only of individual claims. The AAA Rules are at {RemoteLink('https://www.adr.org', 'www.adr.org')}. If there is a conflict between the AAA Rules and these Terms of Use, these Terms of Use shall govern. The arbitrator is bound by these Terms of Use. The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of these Terms of Use, including, but not limited to, any claim that all or part of these Terms of Use is void or voidable. The arbitrator’s award shall be binding on the parties and may be entered as a judgment in any court of competent jurisdiction.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XVIII.</span>
          <span className={'policy-section-title'}>Limitation on Time to File Claims</span>
        </h2>
        <p><b>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</b></p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XIX.</span>
          <span className={'policy-section-title'}>Waiver and Severability</span>
        </h2>
        <p>No waiver by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
        <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
      </div>

      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XX.</span>
          <span className={'policy-section-title'}>Entire Agreement</span>
        </h2>
        <p>The Terms of Use, our Privacy Policy and any applicable SAAS, or subscription agreement constitute the sole and entire agreement between you and Stak Corp. with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website.</p>
      </div>
      <div className={'policy-section'}>
        <h2>
          <span className={'policy-section-number'}>XXI.</span>
          <span className={'policy-section-title'}>Your Comments and Concerns</span>
        </h2>
        <p>This website is operated by Stak Corp., a corporation organized under the laws of New York. All feedback, comments, requests for technical support, notices of copyright infringement claims, and other communications relating to the Website should be directed to: {EmailUs}.</p>
        <p>Thank you for visiting the Website.</p>
      </div>
    </div>
  );
}
