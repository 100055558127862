import React from 'react';
import PropTypes from 'prop-types';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Box, Button } from '@mui/material';
import { getConfig } from '../config';
import axios from 'axios';

const { apiOrigin = 'http://localhost:3001', authorizeNetHost } = getConfig();

export default function OpenCheckout({ ticket, setPaymentProcessing, paymentProcessing }) {
  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      alert('Order placed! You will receive an email confirmation.');
    }
    if (query.get('canceled')) {
      alert("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, []);

  const handleClick = async () => {
    setPaymentProcessing(true);
    const payload = ticket;
    const api = axios.create({
      baseURL: `${apiOrigin}/api/`,
    });
    const response = await api.post('/make-ticket-payment', payload);
    window.document.getElementById('paymentToken').value = response.data.token;
    let payForm = window.document.getElementById('send_hptoken');
    payForm.submit();
    // window.location.href = "/";
    console.log(response);
  };
  return (
    <GridItem xs={12} sm={12} align='center'>
      {(paymentProcessing && (
        <iframe
          id='load_payment'
          className='embed-responsive-item'
          name='load_payment'
          title='load payment'
          width='100%'
          height='850px'
          frameBorder={0}
          scrolling='no'
          hidden='true'
        ></iframe>
      )) ||
        null}
      <form
        id='send_hptoken'
        action={`https://${authorizeNetHost}/payment/payment`}
        method='post'
        // target="load_payment"
        formTarget='_blank'
      >
        <input type='hidden' name='token' value='' id='paymentToken' />
      </form>
      <Box align='center'>
        <Button
          color='primary'
          variant='contained'
          fullWidth
          id='checkout-button'
          disabled={paymentProcessing}
          role='link'
          onClick={handleClick}
        >
          Make Payment
        </Button>
      </Box>
      {/* </List> */}
    </GridItem>
  );
}

OpenCheckout.propTypes = {
  ticket: PropTypes.any,
  setPaymentProcessing: PropTypes.func,
  paymentProcessing: PropTypes.bool,
};

OpenCheckout.defaultProps = {
  ticket: {},
  setPaymentProcessing: () => {},
  paymentProcessing: false,
};
