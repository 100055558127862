import React, { createContext, useReducer, useState, useEffect } from 'react';
import Reducer from 'Reducer';
import defaultState from './defaultState';
import PropTypes from 'prop-types';

const localAppState = JSON.parse(localStorage.getItem('localState'));
export const Context = createContext(localAppState || defaultState);
const Store = ({ children }) => {
  Store.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const [state, dispatch] = useReducer(Reducer, localAppState || defaultState);

  useEffect(() => {
    localStorage.setItem('localState', JSON.stringify(state));
  }, [state]);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const UserLocationContext = createContext({
  userLocation: null,
});
export const UserLocationProvider = ({ children }) => {
  UserLocationProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const localUserLocation = JSON.parse(localStorage.getItem('localUserLocation'));
  const [userLocation, setUserLocation] = useState(localUserLocation || null);

  useEffect(() => {
    localStorage.setItem('localUserLocation', JSON.stringify(userLocation));
  }, [userLocation]);

  return (
    <UserLocationContext.Provider value={{ userLocation, setUserLocation }}>
      {children}
    </UserLocationContext.Provider>
  );
};

export default Store;
