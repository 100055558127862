const defaultState = {
  apiToken: null,
  user: {},
  parkingSession: null,
  vehicles: [],
  error: null,
  notificationCount: 0,
  paymentMethod: false,
  tncChange: false,
};

export default defaultState;
