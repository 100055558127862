import React from 'react';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Typography } from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import { useParams } from 'react-router';
import useAPI from 'useAPI';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import moment from 'moment';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  ...styles,

  inputLabel: {
    color: '#008BE8',
    font: 'normal normal bold 16px/19px Lato',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  sidetext: {
    color: '#324152',
    float: 'left',
    paddingLeft: '10px',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
}));

const formatPayment = (session) => {
  if (!session?.end_time) {
    return '-';
  }
  return `$${(session?.total_amount || 0).toFixed(2)}`;
}

function SessionDetail() {
  let navigate = useNavigate();
  const classes = useStyles();

  const api = useAPI();
  const [sessionDetail, setSessionDetail] = React.useState(['']);
  let { id } = useParams();

  const handleBack = () => {
    navigate('/notifications');
  };

  React.useEffect(() => {
    api
      .get('/session-detail/' + id)
      .then((res) => {
        setSessionDetail(res.data);
      })
      .catch((e) => console.error(e));
  }, []);
  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center'>
        {sessionDetail['vehicle'] && (
          <Card className={classes.root}>
            <Typography variant='h5' component='h2'>
              Parking Details
            </Typography>
            <CardContent>
              <Grid
                item
                container
                direction='row'
                alignItems='center'
                xs={12}
                sm={12}
                md={12}
                align='center'
                spacing={0}
              >
                <Grid item xs={6} sm={6}>
                  <Typography className={classes.sidetext}>Vehicle</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ color: '#324152' }}>
                    {sessionDetail['vehicle'].make} - {sessionDetail['vehicle'].model}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction='row'
                xs={12}
                sm={12}
                md={12}
                spacing={0}
              >
                <Grid item xs={6} sm={6}>
                  <Typography className={classes.sidetext}>Start time</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ color: '#324152' }}>
                    {sessionDetail.start_time ? moment(sessionDetail.start_time).format('MM/DD/YYYY HH:mm:ss') : '-'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction='row'
                alignItems='center'
                xs={12}
                sm={12}
                md={12}
                align='center'
                spacing={0}
              >
                <Grid item xs={6} sm={6}>
                  <Typography className={classes.sidetext}>End time</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ color: '#324152' }}>
                    {sessionDetail.end_time
                      ? moment(sessionDetail.end_time).format('MM/DD/YYYY HH:mm:ss')
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction='row'
                alignItems='center'
                xs={12}
                sm={12}
                md={12}
                align='center'
                spacing={0}
              >
                <Grid item xs={6} sm={6}>
                  <Typography className={classes.sidetext}>Amount Paid</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography style={{ color: '#324152' }}>{formatPayment(sessionDetail)}</Typography>
                </Grid>
              </Grid>
            </CardContent>

            <Button
              onClick={handleBack}
              color='primary'
              variant='contained'
              fullWidth
              style={{
                marginTop: 20,
                color: 'white',
              }}
            >
              Back
            </Button>
          </Card>
        )}
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default SessionDetail;
