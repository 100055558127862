import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import {styled} from '@mui/system';
import {useConfirm} from 'material-ui-confirm';

const dialogButtonStyle = { marginTop: 20, color: 'white' };

const CompletedStepLabel = styled(StepLabel)({
  '.MuiStepLabel-active': {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  '.MuiStepLabel-completed': {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
});

export const ParkingChecklist = [
  'Vehicle is in park',
  'Engine is off',
  'Keys and necessary belongings are out of the vehicle',
  'Antennas are stowed',
  'Car lights are off',
  'Doors, trunk, tailgate are closed',
  'There are no people or pets in the vehicle or parking bay',
]

export function ReparkChecklist(carouselNickname) {
  return [
    `Your vehicle is ready at ${carouselNickname}`,
    'Be cautious when stepping onto the parking platform',
    'Do not move your car at all',
    'Retrieve your items from the vehicle',
    'Close all doors and trunk',
    'Leave your vehicle on the platform and exit the parking bay',
  ]
}

/**
 * Show a safety checklist using a custom MUI stepper that doesn't actually "step".
 * Historically we had the user click a "Next" button after each step, but that was removed.
 * However, the styling of the MUI stepper has remained.
 */
export default function SafetyChecklist({
    isOpen,
    checklist,
    stowFn,
    stowText,
    cancelFn,
    cancelText,
    secondaryFn,
    secondaryText,
  }) {

  const confirm = useConfirm();
  return <Dialog
    open={isOpen}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
  >
    <DialogTitle id='alert-dialog-title'>Safety Check</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description' component='div'>
        <Stepper activeStep={0} orientation='vertical'>
          {checklist.map((label) => (
            <Step key={label} completed>
              <CompletedStepLabel>{label}</CompletedStepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <div style={{textAlign: 'left'}}>
        <Paper square elevation={0} style={{float: 'left', paddingLeft: 20}}>
          <Typography>Thank you for safety check.</Typography>
        </Paper>
        {stowFn &&
          <Button
            onClick={stowFn}
            color='primary'
            variant='contained'
            fullWidth
            style={dialogButtonStyle}
          >
            {stowText}
          </Button> }
        {secondaryFn &&
          <Button
            onClick={secondaryFn}
            color='primary'
            variant='contained'
            fullWidth
            style={dialogButtonStyle}
          >
            {secondaryText}
          </Button> }
        {cancelFn ?
          <Button
            onClick={() => {
              confirm({ description: 'Are you sure you want to cancel?', confirmationText: 'Yes, cancel', cancellationText: 'No, continue'})
                .then(() => cancelFn())
                .catch(() => {
                  // to suppress console logging on cancel
                });
            }}
          >
            {cancelText}
          </Button> : <div style={{marginBottom: 10}}/>
        }
      </div>
    </DialogActions>
  </Dialog>

}

SafetyChecklist.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  checklist: PropTypes.arrayOf(PropTypes.string).isRequired,
  stowFn: PropTypes.func.isRequired,
  stowText: PropTypes.string.isRequired,
  cancelFn: PropTypes.func,
  cancelText: PropTypes.string,
  secondaryFn: PropTypes.func,
  secondaryText: PropTypes.string,
}
