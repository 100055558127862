import React from 'react';
import { createRoot } from 'react-dom/client';
import mixpanel from 'mixpanel-browser';
import App from './App';
import { getConfig } from './config';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import 'assets/scss/material-kit-react.scss?v=1.9.0';
import './index.css';

import * as serviceWorker from './serviceWorker';
import Store from 'Store';
import { UserLocationProvider } from './Store'

// Please see https://auth0.github.io/auth0-react/interfaces/auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();
if (config.mixpanelToken) {
  mixpanel.init(config.mixpanelToken, {debug: false});
} else {
  mixpanel.init('dummy_token', {debug: false});
  mixpanel.disable();
}

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithRedirectCallback.propTypes = {
  children: PropTypes.any.isRequired,
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Store>
    <UserLocationProvider>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={config.domain}
          clientId={config.clientId}
          cacheLocation='localstorage'
          authorizationParams={{
            audience: config.audience,
            scope: 'read:users',
            redirect_uri: window.location.origin,
          }}
        >
          <App />
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </UserLocationProvider>
  </Store>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
