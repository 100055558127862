import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { AppBar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-kit-react/components/headerStyle.js';
import classNames from 'classnames';

const useStyles = makeStyles(styles);

const BrandBar = () => {
  const classes = useStyles();

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.absolute]: false,
    [classes.fixed]: false,
  });
  return (
    <div className='nav-container'>
      <AppBar className={appBarClasses} style={{ background: 'transparent', boxShadow: 'none' }}>
        <RouterNavLink to='/' className='custom-logo' />
      </AppBar>
    </div>
  );
};

export default BrandBar;
