import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import {useAuth0} from '@auth0/auth0-react';
import Button from 'components/CustomButtons/Button.js';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  contentCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: '3',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    padding: '0 15px',
    width: '100%',
    maxWidth: '880px',
  },
  title: {
    textDecoration: 'none',
    fontWeight: '300',
    marginTop: '30px',
    marginBottom: '25px',
    minHeight: '32px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    '& small': {
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
    subTitle: {
      fontSize: '2.25rem',
      marginTop: '0',
      marginBottom: '8px',
    },
  },
});

export default function ErrorPage(props) {
  const classes = useStyles();
  const query = new URLSearchParams(window.location.search);
  const { logout } = useAuth0();
  const title = query.has('error') ? String(query.get('error')).toUpperCase() : 'Error';
  const subTitle = query.has('error_description') ? String(query.get('error_description')).toUpperCase() : undefined;

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>{title}</h1>
          {!!subTitle &&
            <h2 className={classes.subTitle}>
              {subTitle}
            </h2>
          }
          <h2 className={classes.subTitle}>{props.message}</h2>
          <Button
            color='warning'
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                }
              });
            }}
          >
            Back to login
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ErrorPage.defaultProps = {
  message: null,
};
ErrorPage.propTypes = {
  message: PropTypes.string,
};
