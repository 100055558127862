import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Context } from 'Store';
import { useAuth0 } from '@auth0/auth0-react';
import useAPI from 'useAPI';
import types from 'Reducer/types';
import Badge from '@mui/material/Badge';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import MenuIcon from '@mui/icons-material/Menu';
import UserService from '../services/UserService';
import mixpanel from 'mixpanel-browser';
const roundRadius = 20;

const useStyles = makeStyles(() => ({
  roundedBottomBar: {
    width: '100%',
    borderTopLeftRadius: roundRadius,
    borderTopRightRadius: roundRadius,
    position: 'fixed',
    bottom: 0,
    '& .MuiBottomNavigationAction-root': {
      '@media (max-width: 768px)': {
        minWidth: 'auto',
        padding: '6px',
      },
    },
  },

  badgeTransform: {
    transform: 'translate(-300%, 50%)',
  },
}));

const BottomTabs = () => {
  let navigate = useNavigate();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { state, dispatch } = React.useContext(Context);
  const { parkingSession, user } = state;
  const { isAuthenticated } = useAuth0();
  const api = useAPI();

  useEffect(() => {
    UserService.init(api);
    if (isAuthenticated) {
      UserService.getUser().then((user) => {
        mixpanel.identify(user.id);
        dispatch({ type: types.SET_USER, payload: user });
        if (user.is_new || !user.is_agree || !user.phone_verified) {
          navigate('/profile');
        }
      }).catch((e) => {
        console.error(e);
      });
    }
  }, [isAuthenticated]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 'home') {
      navigate('/');
    }
    if (newValue === 'notification') {
      navigate('/notifications');
    }
    if (newValue === 'settings') {
      navigate('/settings');
    }
    if (newValue === 'help') {
      navigate('/help');
    }
  };

  if (!isAuthenticated || !user?.id) {
    return null;
  }

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels={false}
        style={{ backgroundColor: '#f1f1f1', zIndex: 1 }}
        className={classes.roundedBottomBar}
      >
        {(parkingSession && parkingSession.user_vehicle_pin && (
          <BottomNavigationAction
            value='recents'
            icon={
              <h5 style={{ width: '100px', minWidth: '80%' }}>
                <Link
                  component='a'
                  variant='body2'
                  color='primary'
                  onClick={() => {
                    console.info('Checking vehicle status.');
                  }}
                >
                  PIN: {parkingSession.user_vehicle_pin}
                </Link>
              </h5>
            }
          />
        )) ||
          null}
        <BottomNavigationAction
          value='notification'
          icon={
            <Badge badgeContent={state.notificationCount} color='primary'>
              <NotificationsIcon />
            </Badge>
          }
        />
        <BottomNavigationAction value='home' icon={<LocalParkingIcon />} style={{ color: "currentcolor" }} disabled={!user?.phone_verified}/>
        <BottomNavigationAction value='help' icon={<LiveHelpOutlinedIcon />} />
        <BottomNavigationAction value='settings' icon={<MenuIcon />} />
      </BottomNavigation>
    </>
  );
};

export default BottomTabs;
