import React from 'react';
import PropTypes from 'prop-types';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Box, Button } from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import { loadStripe } from '@stripe/stripe-js';
import useAPI from 'useAPI';
import { getConfig } from '../config';

const config = getConfig();

export default function Checkout({ ticket }) {
  const api = useAPI();
  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      alert('Order placed! You will receive an email confirmation.');
    }
    if (query.get('canceled')) {
      alert("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, []);
  const handleClick = async () => {
    const stripe = await loadStripe(config.stripePublishKey);
    const payload = ticket;
    const response = await api.post('/create-checkout-session', payload);
    const session = response.data;
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      console.log('ERROR');
    }
  };
  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} align='center'>
        {/* <List className={classes.root} subheader={<li />}> */}
        {/* <ListSubheader>{`Payment via Stripe`}</ListSubheader> */}
        <Box align='center'>
          <Button
            color='primary'
            variant='contained'
            fullWidth
            id='checkout-button'
            role='link'
            onClick={handleClick}
          >
            Make Payment
          </Button>
        </Box>
        {/* </List> */}
      </GridItem>
    </AuthenticatedContainer>
  );
}

Checkout.propTypes = {
  ticket: PropTypes.any,
};

Checkout.defaultProps = {
  ticket: {},
};
