import Axios from 'axios';
import {convertNullsToEmptyStrings, convertApiError} from '../utils/Utils';

let api = Axios;
const init = async (axiosApi) => {
  api = axiosApi;
};

const getUser = async () => {
  try {
    const response = await api.get('/user');
    let userInfo = response.data;
    convertNullsToEmptyStrings(userInfo);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const updateUser = async (data) => {
  try {
    const response = await api.post('/user', data);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const updateTermsAndConditions = async (user, agree) => {
  const patch = {
    ...user,
    is_new: 0,
    is_agree: agree ? 1 : 0,
  }
  return updateUser(patch);
}

const getMemberships = async () => {
  try {
    const response = await api.get('/memberships');
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const UserService = {
  init,
  getUser,
  updateUser,
  updateTermsAndConditions,
  getMemberships,
};

export default UserService;
