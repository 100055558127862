import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAPI from 'useAPI';
import PhotoService from '../services/PhotoService';
import VehicleService from '../services/VehicleService';
import {Grid, Paper, Box, Modal, Backdrop, Typography} from '@mui/material';
import Button from '../components/CustomButtons/Button';
import { formatUTCDateInLocalTime } from '../utils/Utils';

export default function VehiclePhotos() {
  const api = useAPI();
  const navigate = useNavigate();
  const { id: vehicleId } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const handleOpen = (url) => {
    setSelectedPhoto(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    PhotoService.init(api);
    VehicleService.init(api);
    fetchVehicle();
    fetchPhotos();
  }, [api]);

  const fetchVehicle = async () => {
    try {
      const res = await VehicleService.getVehicleById(vehicleId);
      setVehicle(res);
    } catch (err) {
      alert(err);
    }
  };

  const fetchPhotos = async () => {
    try {
      const resp = await PhotoService.getVehiclePhotos(vehicleId);
      setPhotos(resp.items);
    } catch (err) {
      alert(err);
    }
  }

  return (
    (photos && vehicle) ?
      <Box sx={{flexGrow: 1, overflowY: 'auto'}}>
        <Typography variant="body1" sx={{mt: 0.5, textAlign: 'center', fontWeight: 500}}>
          {vehicle.make} {vehicle.model} Photos
        </Typography>
        <Grid container spacing={2} padding={2}>
          {photos.map((item, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Paper
                elevation={3}
                sx={{
                  height: 260, // Increased height to accommodate text
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
                onClick={() => handleOpen(item.url)}
              >
                <div style={{
                  height: 200,
                  backgroundImage: `url(${item.url})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}/>
                <Typography variant="caption" sx={{mt: 0.5, textAlign: 'center'}}>
                  {formatUTCDateInLocalTime(item.lastModified, true)}
                </Typography>
                {item.metadata?.description &&
                  <Typography variant="caption" sx={{textAlign: 'center', lineHeight: '1.2em', px: '3px'}}>
                    {item.metadata.description}
                  </Typography>
                }
              </Paper>
            </Grid>
          ))}
        </Grid>
        <div style={{textAlign: 'center'}}>
          <Button color='primary' onClick={() => navigate(-1)}>Back</Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          slots={{backdrop: Backdrop}}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)', // Darker backdrop with 85% opacity
                backdropFilter: 'blur(3px)' // Optional: apply a blur effect to the backdrop
              },
            },
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '90%', // Set width to 90% of viewport
              maxWidth: 'none', // Ensures the modal can be 90% width without being constrained
            }}
          >
            <img src={selectedPhoto} alt="Preview" style={{width: '100%', height: 'auto'}}/>
          </Box>
        </Modal>
      </Box>
: null
);
}
