import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import { Typography, IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import PaymentService from '../../services/PaymentService';
import useAPI from 'useAPI';
import { green, red } from '@mui/material/colors';
import ReceiptIcon from '@mui/icons-material/Receipt';

import moment from 'moment';

// import Autocomplete from "@mui/lab/Autocomplete";

// const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    color: '#324152',
    marginTop: 20,
    marginBottom: 80,
  },
  tabStyle: { color: '#324152', marginTop: 20 },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

function SubscriptionTransactions() {
  const classes = useStyles();

  const api = useAPI();
  const [transactions, setTransactions] = React.useState([]);

  const fetchTransactions = async () => {
    const resposne = await PaymentService.getSubscriptionTransactions();
    setTransactions(resposne);
  };
  const getDate = (dateTime) => {
    // return new Date(dateTime);
    return moment(dateTime).format('L');
  };
  React.useEffect(() => {
    PaymentService.init(api);
    fetchTransactions();
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (transaction) => {
    PaymentService.getSubscriptionInvoiceLink(transaction).then((result) => {
      openInNewTab(result);
    });
  };

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography variant='h5' style={{ color: '#324152' }}>
          Payment History
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <div className={classes.root}>
          <List>
            {transactions &&
              transactions.map((transaction) => (
                <ListItem
                  divider
                  key={transaction.id}
                  button
                  value={transaction}
                  style={{
                    backgroundColor: transaction.payment_status === 'paid' ? green[100] : red[100],
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <ListItemText
                    primary={` Amount: $${transaction.plan.amount} for ${
                      transaction.plan.plan_name || ''
                    }`}
                    primaryTypographyProps={{
                      style: { color: '#111', fontSize: 14 },
                    }}
                    secondary={
                      <React.Fragment>
                        Start Date: {getDate(transaction.start_date)}
                        <br />
                        {/* Next Billing Date: {getDate(transaction.next_billing_date)} 
                        <br />*/}
                        Vehicle: {transaction.vehicle_name}
                        <br />
                        Status: {String(transaction.payment_status).toUpperCase()}
                        <br />
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => viewInvoice(transaction)} size='large'>
                      <ReceiptIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default SubscriptionTransactions;
