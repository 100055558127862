import React, { Fragment } from 'react';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
// import { Typography } from "@mui/material";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import Fab from "@mui/material/Fab";
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import UnAuthorizedScreen from './UnAuthorizedScreen';

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function AuthenticatedContainer({ unAuthChildren, children }) {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  return (
    <Fragment>
      <div className={classes.container}>
        <GridContainer justify='center'>
          {(isAuthenticated && children) || unAuthChildren || <UnAuthorizedScreen />}
        </GridContainer>
      </div>
    </Fragment>
  );
}

AuthenticatedContainer.defaultProps = {
  children: null,
  unAuthChildren: null,
};
AuthenticatedContainer.propTypes = {
  children: PropTypes.any,
  unAuthChildren: PropTypes.any,
};
