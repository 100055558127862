import React from 'react';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Typography } from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import VehicleForm from './VehicleForm';

function NewVehicle() {
  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography variant='h5' style={{ color: '#324152' }}>
          New Vehicle
        </Typography>
      </GridItem>
      <VehicleForm />
    </AuthenticatedContainer>
  );
}

export default NewVehicle;
