import React, { useEffect, useContext, useState } from 'react';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import LocationService from '../../services/LocationService';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import useAPI from 'useAPI';
import PropTypes from 'prop-types';
import { useGeolocation } from './GeolocationContext';
import { UserLocationContext } from '../../Store';

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  newButton: { marginTop: 20, color: 'white' },
}));

function Location(props) {
  const { showMenu = true } = props;
  const classes = useStyles();
  const api = useAPI();
  const { userLocation, setUserLocation } = useContext(UserLocationContext);
  const { latitude, longitude } = useGeolocation();
  const [locationList, setLocationList] = useState([]);

  const handleChange = (val) => {
    const selectedLoc = locationList.filter((loc) => loc.id === val)[0];
    setUserLocation(selectedLoc);
  };

  const updateLocationList = async () => {
    let locations = locationList;
    if (locationList.length === 0) {
      locations = await LocationService.getLocations();
      setLocationList(locations);
    }
    if (locations.length === 1) {
      setUserLocation(locations[0]);
    } else if (!userLocation) {
      let closest = Infinity;
      let nearestLocationObj = null
      for (const loc of locations) {
        const distance = LocationService.distanceInKm(latitude, longitude, loc.latitude, loc.longitude)
        if (distance <= closest) {
          closest = distance;
          nearestLocationObj = loc;
        }
      }
      if (nearestLocationObj) {
        setUserLocation(nearestLocationObj);
      } else {
        setUserLocation(locations[0]);
      }
    }
  };

  useEffect(() => {
    LocationService.init(api);
    updateLocationList();
  }, [latitude, longitude]);

  return (
    showMenu && (
      <GridItem xs={12} sm={12} md={8} align='center'>
        <FormControl className={classes.formControl}>
          <InputLabel id='demo-simple-select-outlined-label'>Location</InputLabel>
          <Select
            style={{ width: 300 }}
            label='Location'
            value={locationList && locationList.length > 0 ? (userLocation? userLocation.id : locationList[0].id) : ''}
            required
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            readOnly={locationList && locationList.length === 1}
            inputProps={
              locationList && locationList.length === 1 ? { IconComponent: () => null } : null
            } // hide arrow if read-only
          >
            <MenuItem
              disabled
              value={''}
            >
              Choose Location
            </MenuItem>

            {locationList &&
              locationList.map((location) => (
                <MenuItem
                  value={location.id}
                  key={location.id}
                >
                  {location.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </GridItem>
    )
  );
}

Location.propTypes = {
  showMenu: PropTypes.bool,
};

export default Location;
