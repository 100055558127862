import React from 'react';

// eslint-disable-next-line react/prop-types
function TicketReceiptTransactions({ transactions = [] }) {
  return (
    <>
      {(transactions &&
        transactions.length > 0 &&
        transactions.map((transactionsItem) => {
          if (transactionsItem && transactionsItem.transaction) {
            const { transaction } = transactionsItem;
            return (
              <div key={transaction.transId}>
                <h5>
                  {transaction.order.invoiceNumber} | {transaction.transId} | Status:{' '}
                  {String(transaction.transactionStatus).toLowerCase()}
                </h5>
                <h6>
                  Paid via: {transaction?.payment?.creditCard?.cardType} -{' '}
                  {transaction?.payment?.creditCard?.cardNumber}
                </h6>
                <table style={{ margin: 20, padding: 20 }}>
                  <thead>
                    <tr style={{ padding: 5 }}>
                      <th style={{ width: '100%' }}>Service</th>
                      <th style={{ width: '200px', textAlign: 'right' }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction.lineItems.lineItem.map((lineItem) => (
                      <tr key={lineItem.name}>
                        <td>{lineItem.description || lineItem.name}</td>
                        <td style={{ textAlign: 'right' }}>${lineItem.unitPrice?.toFixed(2)}</td>
                      </tr>
                    ))}
                    <tr>
                      <th>Total</th>
                      <th style={{ textAlign: 'right' }}>
                        <b>${transaction.authAmount?.toFixed(2)}</b>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          } else {
            return null;
          }
        })) ||
        null}
    </>
  );
}

export default TicketReceiptTransactions;
