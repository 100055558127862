import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import {QRCodeSVG} from 'qrcode.react';

/**
 * Note that the "window.innerWidth" code below will not update when the screen is resized, but this should not be needed
 */
function ShowQRDialog({ classes, isOpen, title, code, onCancel }) {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      classes={{ paper: classes.qrDialog }}
    >
      <DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        { isOpen && <QRCodeSVG size={window.innerWidth - 90} value={code} /> }
        {onCancel && (
          <Button
            onClick={onCancel}
            color='primary'
            variant='contained'
            fullWidth
            className={classes.newButton}
          >
            Cancel
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
}

ShowQRDialog.propTypes = {
  classes: PropTypes.any,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  code: PropTypes.string,
  onCancel: PropTypes.func,
};

export default ShowQRDialog;
