const types = {
  SET_USER: 'SET_USER',
  SET_VEHICLES: 'SET_VEHICLES',
  SET_PARKING_SESSION: 'SET_PARKING_SESSION',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  SET_TNC_CHANGE: 'SET_TNC_CHANGE',
  SET_API_TOKEN: 'SET_API_TOKEN',
};

export default types;
