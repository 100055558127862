import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {ExpressCheckoutElement, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Button} from '@mui/material';

export default function StripeCheckout({clientSecret, cancelFn, user}) {
  const stripe = useStripe();
  const elements = useElements();
  const [isComplete, setIsComplete] = useState(false);
  const [key, setKey] = useState(0);

  const returnUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      return `${window.location.protocol}//${window.location.host}/`;
    }
    return '/'; // Fallback for server-side rendering (in distant future?)
  }, []);

  const refreshPaymentElement = useCallback(() => {
    setKey(prevKey => prevKey + 1);
  }, []);

  const handlePaymentElementChange = useCallback((event) => {
    console.log('PaymentElement change:', event);
    setIsComplete(event.complete);
    //refreshPaymentElement(); // Causes infinite loop. Unclear how to refresh only after editing saved card, which is not working in any case.
  }, [refreshPaymentElement]);

  const handleSubmit = async (event) => {
    console.log('handleSubmit', event);
    const isExpressCheckout = !event.preventDefault;
    if (!isExpressCheckout) {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
    }

    if (!stripe || !elements) {
      console.log('No stripe or elements');
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error: submitError} = await elements.submit();
    if (submitError) {
      console.error('Error submitting elements:', submitError);
      alert(submitError.message);
      cancelFn();
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: returnUrl,
      },
      redirect: 'always',
    });

    if (result.error) {
      console.error('Error confirming payment:', result.error);
      alert(result.error.message);
      cancelFn();
    } else {
      console.log('confirmPayment result:', result);
    }
  };

  return <form onSubmit={handleSubmit}>
    <div>
      <ExpressCheckoutElement
        options={{
          paymentMethods: {
            applePay: 'always',
            googlePay: 'always',
          },
          layout: {
            maxColumns: 1,
            overflow: 'never',
          },
        }}
        onConfirm={event => handleSubmit(event)}/>
    </div>
    <div>
      <h3>Pay with Card</h3>
      <PaymentElement
        key={key}
        options={{
          paymentMethodOrder: ['card'],
          defaultValues: {
            billingDetails: {
              name: user.name,
            }
          },
          fields: {
            billingDetails: {
              name: 'auto',
            }
          },
          wallets: {
            applePay: 'auto',
            googlePay: 'auto'
          },
          savedPaymentMethods: {
            allowSelection: true,
            displayInOrder: ['recentlyUsed', 'frequentlyUsed'],
          },
        }}
        onChange={handlePaymentElementChange}
        onLoadError={(event) => {
          console.error('PaymentElement load error:', event);
          refreshPaymentElement();
        }}
      />
    </div>
    <Button type='submit' color='primary' disabled={!isComplete}>
      Confirm Payment
    </Button>
    <Button onClick={cancelFn} color='secondary'>
      Cancel, I want to stay
    </Button>
  </form>
}

StripeCheckout.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  cancelFn: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}
