import { Box, CircularProgress } from '@mui/material';
import React from 'react';
// import loading from "../assets/loading.svg";

const Loading = () => (
  <div className='spinner'>
    <Box
      display='flex'
      m='auto'
      p={10}
      width={500}
      height={500}
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress size='3rem' />
    </Box>
  </div>
);

export default Loading;
