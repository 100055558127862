/* eslint-disable react/display-name */

import React from 'react';

import AuthenticatedContainer from 'components/AuthenticatedContainer';
import Box from '@mui/material/Box';
import TermsOfUseContent from '../Components/TermsOfUseContent';

export const TermsOfUse = () => {

  return (
    <AuthenticatedContainer>
      <Box sx={{ width: '100%' }}>
        <TermsOfUseContent/>
      </Box>
    </AuthenticatedContainer>
  );
};

export default TermsOfUse;
