import Axios from 'axios';
import { convertApiError } from '../utils/Utils';

let api = Axios;
const init = async (axiosApi) => {
  api = axiosApi;
};

const getNotifications = async () => {
  try {
    const response = await api.get('/notifications');
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getNotificationStatus = async () => {
  try {
    const response = await api.get('/notifications/status');
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const updateNotificationStatus = async (data) => {
  try {
    const response = await api.post('/notifications/status', data);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const sendUserMessage = async (message) => {
  try {
    const response = await api.post('/notifications/message', { message });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const NotificationService = {
  init,
  getNotifications,
  getNotificationStatus,
  updateNotificationStatus,
  sendUserMessage,
};

export default NotificationService;
