/* eslint-disable react/display-name */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Row } from 'reactstrap';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import useAPI from 'useAPI';
import { ListItem, ListItemText, Typography } from '@mui/material';
import GridItem from 'components/Grid/GridItem';
import moment from 'moment';
import ParkingSessionService from 'services/ParkingSessionService';
makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));
export const ParkingHistoryForSessions = () => {
  const api = useAPI();
  const [parkingHistory, setParkingHistory] = React.useState([]);

  const fetchParkingHistory = async () => {
    const history = await ParkingSessionService.listAllUserSessions();
    console.log('history', history);
    setParkingHistory(history);
  };

  const getTime = (notificationDateTime) => {
    return moment(notificationDateTime).fromNow();
  };

  React.useEffect(() => {
    ParkingSessionService.init(api);
    fetchParkingHistory();
  }, [api]);

  const renderParkingHistory = (item, index) => {
    console.log(index, index ? ', ' : '');

    return (
      <ListItem
        divider
        key={item.id}
        button
        value={item}
        style={{
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <ListItemText
          primary={`${moment(item.start_time).format('MM/DD/YYYY HH:mm:ss')} - 
          ${item.end_time ? moment(item.end_time).format('MM/DD/YYYY HH:mm:ss') : 'Parked'}`}
          primaryTypographyProps={{
            style: { color: '#111', fontSize: 14 },
          }}
          secondary={
            <React.Fragment>
              {getTime(item.start_time)} | {String(item.status).toUpperCase()}
            </React.Fragment>
          }
        />
      </ListItem>
    );
  };

  return (
    <AuthenticatedContainer>
      <Container className='mb-5'>
        <Row>
          <GridItem align='center' className='mb-2'>
            <Typography variant='h5' color='primary' style={{ color: '#394253' }}>
              My Parking History
            </Typography>
          </GridItem>
          <GridItem align='center' className='mb-2'>
            {parkingHistory.map((notification, index) => {
              return renderParkingHistory(notification, index);
            })}
          </GridItem>
        </Row>
      </Container>
    </AuthenticatedContainer>
  );
};

export default ParkingHistoryForSessions;
