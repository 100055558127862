import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { UserLocationContext } from './Store';
import Loading from './components/Loading';
import Home from './views/AutoParkHome';
import Profile from './views/Profile';
import About from './views/About';
import Help from './views/Help';
import Retrieve from './views/Retrieve';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ConfirmProvider } from "material-ui-confirm";
import { GeolocationProvider } from './views/Components/GeolocationContext';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import './App.css';
import initFontAwesome from './utils/initFontAwesome';
import Settings from './views/Settings/Settings';
import TicketDetail from './views/TicketDetail';
import TicketReceipt from './views/TicketReceipt';
import Transaction from './views/Settings/Transactions';
import Tickets from './views/Settings/Tickets';
import VehicleStatus from './views/VehicleStatus';
import BrandBar from 'components/BrandBar';
import BottomTabs from 'components/BottomTabs';
import { Notifications } from 'views/Notifications';
import SelectVehicle from 'views/SelectVehicle';
import NewVehicle from 'views/Settings/NewVehicle';
import EditVehicle from 'views/Settings/EditVehicle';
import VehiclePhotos from 'views/VehiclePhotos';
import ParkingHistory from 'views/Settings/ParkingHistory';
import Checkout from 'views/Checkout';
import SessionDetail from 'views/SessionDetail';
import ErrorPage from 'views/ErrorPage';
import NotificationSettings from 'views/Settings/NotificationSettings';
import TermsOfUse from 'views/Settings/TermsOfUse';
import PrivacyPolicy from 'views/Settings/PrivacyPolicy';
import NotifyAttendant from 'views/NotifyAttendant';
import SubscriptionTransactions from 'views/Settings/SubscriptionTransactions';
import ParkingHistoryForSessions from 'views/Settings/ParkingHistoryForSessions';
import UnknownUser from './views/UnknownUser';

initFontAwesome();

const Main = () => {
  const { isLoading, error } = useAuth0();
  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id='app' className='d-flex flex-column h-100'>
      <BrandBar />
      <Container
        className='flex-grow-1'
        style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 80 }}
      >
        <Routes>
          <Route path='/ticket/:id' exact element={<TicketDetail />} />
          <Route path='/ticket/:id/payment/success' exact element={<TicketReceipt />} />
          <Route path='/ticket/:id/receipt' exact element={<TicketReceipt />} />
          <Route exact path='/' element={<Home />} />
          <Route exact path='/profile' element={<Profile />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/help' element={<Help />} />
          <Route exact path='/park/vehicle/select' element={<SelectVehicle />} />
          <Route exact path='/park/vehicle/new' element={<NewVehicle />} />
          <Route exact path='/vehicles/manage' element={<SelectVehicle />} />
          <Route exact path='/vehicles/new' element={<NewVehicle />} />
          <Route exact path='/park/vehicle/:id/edit' element={<EditVehicle />} />
          <Route exact path='/vehicles/:id/edit' element={<EditVehicle />} />
          <Route exact path='/vehicles/:id/photos' element={<VehiclePhotos />} />
          <Route exact path='/retrieve' element={<Retrieve />} />
          <Route exact path='/settings' element={<Settings />} />
          <Route exact path='/notifications' element={<Notifications />} />
          <Route exact path='/transactions' element={<Transaction />} />
          <Route exact path='/subscription-transactions' element={<SubscriptionTransactions />} />
          <Route exact path='/tickets' element={<Tickets />} />
          <Route exact path='/checkout' element={<Checkout />} />
          <Route exact path='/session-detail/:id' element={<SessionDetail />} />
          <Route exact path='/vehicle-status' element={<VehicleStatus />} />
          <Route exact path='/parking-history' element={<ParkingHistory />} />
          <Route
            exact
            path='/user-parking-session-history'
            element={<ParkingHistoryForSessions />}
          />
          <Route exact path='/notify-attendant' element={<NotifyAttendant />} />
          <Route exact path='/notifications-settings' element={<NotificationSettings />} />
          <Route exact path='/terms-of-use' element={<TermsOfUse />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/unknown-user' element={<UnknownUser />} />
        </Routes>
      </Container>
      <BottomTabs />
    </div>
  );
};

const ProtectedPage = withAuthenticationRequired(() => <Main />);

const App = () => {
  const { userLocation } = React.useContext(UserLocationContext);
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: orange,
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <GeolocationProvider location={userLocation}>
              <Routes>
                <Route path='*' element={<ProtectedPage />} />
              </Routes>
            </GeolocationProvider>
          </LocalizationProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
