/* eslint-disable react/display-name */

import React from 'react';

import AuthenticatedContainer from 'components/AuthenticatedContainer';
import Box from '@mui/material/Box';
import PrivacyPolicyContent from "../Components/PrivacyPolicyContent"


export const PrivacyPolicy = () => {
  return (
    <AuthenticatedContainer>
      <Box sx={{ width: '100%' }}>
        <PrivacyPolicyContent/>
      </Box>
    </AuthenticatedContainer>
  );
};

export default PrivacyPolicy;
