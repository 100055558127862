import React, {useContext, useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {getConfig} from 'config';
import { useNavigate } from 'react-router-dom';
import types from 'Reducer/types';
import useAPI from 'useAPI';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Countdown from 'react-countdown';
import {useConfirm} from "material-ui-confirm";
import mixpanel from 'mixpanel-browser';
import {Context, UserLocationContext} from 'Store';

import GridItem from 'components/Grid/GridItem.js';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import car from '../assets/img/car.svg';
import parkIcon from '../assets/img/svg/park_icon.svg';
import retrieveIcon from '../assets/img/svg/retrieve_icon.svg';
import UserService from '../services/UserService';
import ParkingSessionService from '../services/ParkingSessionService';
import LocationService from '../services/LocationService';
import Locations from './Components/Locations';
import ScanQRDialog from './Components/ScanQRDialog';
import ShowQRDialog from "./Components/ShowQRDialog"
import GenerateAlert from './Components/PhoneAlert';
import HelpButton from './Components/HelpButton';
import {useGeolocation} from './Components/GeolocationContext';
import SafetyChecklist, {ParkingChecklist, ReparkChecklist} from './Components/SafetyChecklist';
import {
  Box,
  Button,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  TextField,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  LinearProgress,
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import RefreshIcon from '@mui/icons-material/Refresh';
import {loadStripe} from "@stripe/stripe-js"
import {Elements} from '@stripe/react-stripe-js';
const config = getConfig();
const stripePromise = loadStripe(config.stripePublishKey);
import StripeCheckout from './Components/StripeCheckout';
const isTestMode = !!config.testMode;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    height: 100,
    width: '100%',
    cursor: 'pointer',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  park: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  parkingSubtext: {
    fontSize: 18,
    textAlign: 'left',
    lineHeight: '1.2em',
  },
  geoDebug: {
    fontSize: 14,
    fontFamily: 'monospace',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  cover: {
    width: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coverParked: {
    width: 120,
    display: 'flex',
    paddingTop: 20,
    justifyContent: 'center',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardText: {
    font: 'normal normal 900 22px/30px Lato',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: '1',
  },
  smallCardText: {
    font: 'normal normal 900 18px/24px Lato',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: '1',
  },
  smallNote: {
    fontSize: '10pt',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '1.2em',
    paddingTop: 20,
  },
  center: {textAlign: 'center', alignContent: 'center'},
  qrDialog: {textAlign: 'center', alignContent: 'center',
    width: '90%',
    maxWidth: '90%',
    margin: 0,
  },
  qrOverrideButton: {
    marginTop: 20,
    color: 'white',
    fontSize:20,
    lineHeight: '1.2em',
    backgroundColor: '#009900',
    '&:hover': {
      backgroundColor: '#008000',
    },
    '&:active': {
      backgroundColor: '#008000',
    },
  },
  dialogTitle: {lineHeight: '1.2em'},
  parkButtonSubtext: {
    fontSize: 16,
    fontFamily: 'Lato, sans-serif',
  },
  paymentSummary: {
    marginBottom: '10px',
  },
  paymentTotal: {
    borderTopStyle: 'double',
    fontWeight: 'bold',
  }
}));

const FIVE_MINS_IN_MILLIS = 1000 * 60 * 5;

export default function Home() {
  let navigate = useNavigate();
  const classes = useStyles();
  const api = useAPI();
  const { state, dispatch } = useContext(Context);
  const { user, parkingSession = {} } = state;
  const { userLocation } = useContext(UserLocationContext);
  const [parkTime, setParkTime] = useState('');
  const [failed] = useState(false);
  const [errorMessage] = useState('');
  const confirm = useConfirm();
  const {
    active: geolocationActive,
    initializing: geolocationInitializing,
    isDebugMode: isGeolocationDebugMode,
    latitude,
    longitude,
    distanceInFeet,
    feetToDistanceString,
    outlierCount,
    positionCount,
    bufferSize,
  } = useGeolocation();
  const [parkingEnabled, setParkingEnabled] = useState(false);
  const [allowQRParkingOverride, setAllowQRParkingOverride] = useState(false);
  const [parkingText, setParkingText] = useState(null);
  const [parkingSubtext, setParkingSubtext] = useState(null);
  const [geoDebug, setGeoDebug] = useState(null);
  const [parkingOptions, setParkingOptions] = useState(null);
  const [isValetOnly, setValetOnly] = useState(false);

  /** PARK **/
  const [scanQRForStowing, setScanQRForStowing] = useState(false);
  const [isShowParkingToken, setShowParkingToken] = useState(false);
  const [parkingToken, setParkingToken] = useState('');
  const [carouselProgress, setCarouselProgress] = useState(0);

  /** RETRIEVE **/
  const [enableTokenlessRetrieval, setEnableTokenlessRetrieval] = useState(false);
  const [readyToLeave, setReadyToLeave] = useState(false);
  const [_, setTokenForLeaving] = useState();
  const [paymentSummary, setPaymentSummary] = useState(null);
  const [showConfirmPayment, setShowConfirmPayment] = useState(null);
  const [showSubmittingPayment] = useState(false);
  const [showDriveAway, setShowDriveAway] = useState(false);

  /** REPARK **/
  const [readyToRepark, setReadyToRepark] = useState(false);
  const [scanQRForReparkRestowing, setScanQRForReparkRestowing] = useState(false);

  /** Valet retrieval notification */
  const [awaitingValetRetrieval, setAwaitingValetRetrieval] = useState(false);
  const [showValetRetrievedVehicleNotification, setShowValetRetrievedVehicleNotification] = useState(false);

  // Discount
  const [showApplyDiscount, setShowApplyDiscount] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [isDiscountCodeFieldError, setIsDiscountCodeFieldError] = useState(false);

  // Snackbar alerts
  const [snackbarError, setSnackbarError] = useState('');

  const handleEnterDiscountCode = () => {
    setShowApplyDiscount(true);
    mixpanel.track('OPEN_DISCOUNT', {session_id: parkingSession.id});
  }

  const handleCancelDiscount = () => {
    setShowApplyDiscount(false);
    mixpanel.track('CANCEL_DISCOUNT', {session_id: parkingSession.id});
  }

  const handleApplyDiscountCode = async () => {
    if (discountCode === '') {
      setIsDiscountCodeFieldError(true);
      mixpanel.track('APPLY_DISCOUNT_ERROR', {session_id: parkingSession.id});
      return;
    }
    try {
      mixpanel.track('APPLY_DISCOUNT', {session_id: parkingSession.id, discount_code: discountCode});
      const updatedSession = await ParkingSessionService.applyDiscountCode(discountCode, parkingSession.id);
      updateSession(updatedSession);
      setShowApplyDiscount(false);
    } catch (e) {
      console.log('Caught error', e);
      mixpanel.track('APPLY_DISCOUNT_ERROR', {session_id: parkingSession.id, err: e.toString()});
      setSnackbarError(e);
    }
  }

  let activeColor = '#008BE8';
  let disabledColor = '#394253';
  let successColor = '#2E933C';
  let mutedColor = '#CACACA';
  let mainColor = activeColor;
  const urlParams = new URLSearchParams(window.location.search);
  const isPostPayment = useRef(urlParams.has('payment_intent'));

  // Page load effects
  useEffect(() => {
    UserService.init(api);
    ParkingSessionService.init(api);
    mixpanel.track('HOME');
    if (isPostPayment.current) {
      // Handle return from payment
      const paymentId =  urlParams.get('payment_intent');
      const redirectStatus = urlParams.get('redirect_status');
      checkSessionAfterPayment(paymentId, redirectStatus);
    } else {
      refreshSession();
    }
    refreshNotificationCount();
  }, [api]);

  useEffect(() => {
    if (api && userLocation) {
      fetchParkingOptions();
    }
  }, [userLocation, api]);

  const fetchParkingOptions = () => {
    ParkingSessionService.getParkingOptions(userLocation).then(options => {
      setParkingOptions(options);
      setValetOnly(!!options.isValetOnly);
    });
  }

  const isValetParked = (session) => {
    return isValetOnly || session.valet_id;
  }

  const updateSession = (session) => {
    if (!!session && (!parkingSession || session.status !== parkingSession.status)) {
      mixpanel.track(`SESSION_${session.status}`, {session_id: session.id});
    } else if (!!parkingSession && !session) {
      mixpanel.track('SESSION_END', {session_id: parkingSession.id});
    }
    dispatch({
      type: types.SET_PARKING_SESSION,
      payload: session,
    });
    const sessionIsAwaitingValetRetrieval = ParkingSessionService.isAwaitingValetRetrieval(session);
    if (!awaitingValetRetrieval && sessionIsAwaitingValetRetrieval) {
      setAwaitingValetRetrieval(true);
    } else if (awaitingValetRetrieval && !sessionIsAwaitingValetRetrieval) {
      setAwaitingValetRetrieval(false);
      setShowValetRetrievedVehicleNotification(session.status !== 'PARKED');
    }
  }

  const checkSessionAfterPayment = async (paymentId, redirectStatus) => {
    try {
      const session = await ParkingSessionService.getSessionAfterPayment(paymentId, redirectStatus);
      updateSession(session);
    } catch (err) {
      alert(err);
      if (err === 'Invalid request') {
        // Refresh page to remove payment_intent from URL and clear "isPostPayment" ref
        window.location.href = window.location.pathname;
      } else {
        alert(err);
      }
    }
  }

  const refreshSession = (waitingForPaymentToProcess) => {
    ParkingSessionService.getCurrentSession(waitingForPaymentToProcess)
      .then((session) => updateSession(session))
      .catch((e) => console.error(e));
  };

  const refreshNotificationCount = () => {
    api.get('notifications-count')
      .then((res) => {
         dispatch({
           type: types.SET_NOTIFICATIONS,
           payload: res.data,
         });
       })
       .catch((e) => console.error(e));
  }

  const hasExpiredReservation = (session) => {
    if (session.reservation) {
      const then = moment(parkingSession.reservation.end_time);
      if (then.isBefore()) {
        return true;
      }
    }
    return false;
  }

  const updateTime = () => {
    if (hasExpiredReservation(parkingSession)) {
      setParkTime('Reservation has ended. Please retrieve your vehicle.')
      return -1;
    }

    const now = moment();
    let then;
    if (parkingSession.reservation) {
      then = moment(parkingSession.reservation.end_time);
    } else {
      then = moment(parkingSession.start_time);
    }
    const duration = moment.duration(Math.abs(now.diff(then)));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    let timeStr;
    let nextInterval = 60_000; // default every minute
    if (days > 0) {
      timeStr = `${days}d `;
      if (hours > 0 || minutes > 0) {
        timeStr += `${hours}h `;
      }
      if (minutes > 0) {
        timeStr += `${minutes}m`;
      }
    } else if (hours > 0) {
      timeStr = `${hours}h `;
      if (minutes > 0) {
        timeStr += `${minutes}m`;
      } else {
        nextInterval = seconds * 1000;
      }
    } else {
      timeStr = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      nextInterval = 1000; // update every second
    }
    setParkTime(timeStr);
    return nextInterval;
  };

  useEffect(() => {
    if (parkingSession) {
      // Decorate parking session with post-payment status from URL
      parkingSession.isPostPayment = isPostPayment.current;
    }

    if (!(parkingSession && parkingOptions)) {
      postRetrieveCleanup();
      return;
    }
    const timersToCleanup = {};
    const adjustUpdateTimeInterval = () => {
      if (timersToCleanup.updateTimeRef) {
        clearInterval(timersToCleanup.updateTimeRef);
        delete timersToCleanup.updateTimeRef;
      }

      const scheduleNextUpdate = () => {
        const newInterval = updateTime();
        if (newInterval > 0) {
          timersToCleanup.updateTimeRef = setTimeout(() => {
            scheduleNextUpdate(); // Recursively schedule the next update
          }, newInterval);
        }
      };

      // Start recursive scheduling
      scheduleNextUpdate();
    };

    if (ParkingSessionService.isParked(parkingSession)) {
      setCarouselProgress(0);
      mainColor = disabledColor;
      adjustUpdateTimeInterval();
      // While page is open, check on session every 5 mins
      timersToCleanup.refreshSessionRef = setInterval(function () {
        refreshSession();
      }, FIVE_MINS_IN_MILLIS);
    } else if (ParkingSessionService.isWaitingForPaymentToProcess(parkingSession)
      && !showConfirmPayment) { // while payment confirmation is shown, don't update session
      // Once the payment confirmation dialog is closed, we check the session while letting the server know the dialog is no longer open.
      // This tells the server that it should expect a Stripe callback, and if that's not received soon then the server will
      // cancel the payment flow.
      timersToCleanup.updateSessionRef = setInterval(function () {
        refreshSession(true);
      }, 2000);
    } else if (ParkingSessionService.isTransitionalState(parkingSession)) {
      timersToCleanup.updateSessionRef = setInterval(function () {
        refreshSession();
      }, 2000);
    }

    if (ParkingSessionService.isCarouselInUse(parkingSession)) {
      // Takes ~1 minute
      timersToCleanup.carouselTimer = setInterval(() => {
        setCarouselProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 100;
          }
          return oldProgress + (100.0/60.0); // denominator is expected number of secs to stow
        });
      }, 1000);
    }
    if (ParkingSessionService.isRetrievingReadyToLeave(parkingSession) && !parkingSession.valet_id) {
      setShowDriveAway(true);
    }
    if (ParkingSessionService.isReparkingCarAvailable(parkingSession)) {
      setCarouselProgress(0);
    }
    return () => {
      Object.keys(timersToCleanup).forEach(key => {
        clearInterval(timersToCleanup[key]);
        clearTimeout(timersToCleanup[key]);
      });
    }
  }, [parkingSession, parkingOptions]);

  const handleParkingTokenStatusOrTokenlessRetrieve = (paymentSummary, clientSecret, customerSessionClientSecret, parkingToken = null) => {
    console.log('handleParkingTokenStatusOrTokenlessRetrieve: paymentSummary:', paymentSummary);
    if (paymentSummary?.confirmed || paymentSummary?.success || paymentSummary?.payment?.success) {
      GenerateAlert();
      setTimeout(()=> {
        onCancelShowParkingToken();
        refreshSession();
      }, 500); // leave time for alert
    } else if (paymentSummary?.totalAmount) { // payment summary
      GenerateAlert();
      setTimeout(() => {
         if (parkingToken) {
           // retain parking token to submit with retrieve request
           setTokenForLeaving(parkingToken);
         }
         onCancelShowParkingToken();
         setPaymentSummary(paymentSummary);
         setShowConfirmPayment({clientSecret, customerSessionClientSecret});
      }, 500); // leave time for alert
    }
  }

  const checkParkingTokenStatus = () => {
    ParkingSessionService.getParkingTokenStatus(parkingToken)
      .then(resp => {
        if (resp?.confirmed) {
          handleParkingTokenStatusOrTokenlessRetrieve(resp); // {confirmed: true|false}
        } else if (resp?.paymentSummary) {
          handleParkingTokenStatusOrTokenlessRetrieve(resp.paymentSummary, resp.clientSecret, resp.customerSessionClientSecret, parkingToken);
        } // else keep checking
      })
      .catch((e) => {
        GenerateAlert();
        setTimeout(() => {
          onCancelShowParkingToken();
          alert(e);
        }, 500);
      });
  };

  useEffect(() => {
    let tokenStatusTimer = null;
    if (isShowParkingToken) {
      tokenStatusTimer = setInterval(function () {
        checkParkingTokenStatus();
      }, 2000);
    } else {
      clearInterval(tokenStatusTimer);
    }
    return () => {
      tokenStatusTimer && clearInterval(tokenStatusTimer);
    }
  }, [isShowParkingToken]);

  const handleTokenError = (err) => {
    if (typeof err === 'string') {
      alert(err);
    } else if (err.response?.status === 409) { // Invalid session ID
      alert('Your app is out of date. We will refresh it for you, and then try again to request the QR code.')
    }
    location.reload();
  }

  const onScanQRForStowing = async () => {
    if (ParkingSessionService.isOnPallet(parkingSession)) { // extra check
      if (LocationService.locationHasQRReader(userLocation)) {
        try {
          const token = await ParkingSessionService.getParkingToken({session: parkingSession, action: 'STOW'});
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SHOW_QR_TO_STOW', {session_id: parkingSession.id});
        } catch (err) {
          handleTokenError(err);
        }
      } else {
        setScanQRForStowing(true);
        mixpanel.track('OPEN_QR_TO_STOW', {session_id: parkingSession.id});
      }
    }
  }

  const onCancelShowParkingToken = () => {
    setParkingToken('');
    setShowParkingToken(false);
  }

  const handleQRScannedForStowing = (token) => {
    setScanQRForStowing(false);
    mixpanel.track('SCAN_QR_TO_STOW', {session_id: parkingSession.id});
    ParkingSessionService.stowVehicleForParking(token, parkingSession.id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    });
  }

  const handlePark = async (qrCodeOverride) => {
    if (!user.is_agree) {
      navigate('/profile');
      return;
    }
    if (parkingEnabled || (qrCodeOverride && allowQRParkingOverride)) {
      navigate('/park/vehicle/select');
    }
  };

  const handleRefresh = async () => {
    mixpanel.track('REFRESH_SESSION', {session_id: parkingSession?.id});
    await refreshSession();
  };

  const handleGetSomethingFromVehicle = async () => {
    if (ParkingSessionService.isParked(parkingSession)) { // extra check
      if (LocationService.locationHasQRReader(userLocation)) {
        try {
          const token = await ParkingSessionService.getParkingToken({session: parkingSession, action: 'REPARK', location: userLocation});
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
        } catch (err) {
          handleTokenError(err);
        }
      } else {
        mixpanel.track('OPEN_QR_TO_REPARK', {session_id: parkingSession.id});
        setReadyToRepark(true);
      }
    }
  };

  const handleReadyToLeave =async  () => {
    if (ParkingSessionService.isParked(parkingSession)) { // extra check
      if (enableTokenlessRetrieval) {
        try {
          const {paymentSummary, clientSecret, customerSessionClientSecret} = await ParkingSessionService.tokenlessRetrieve();
          handleParkingTokenStatusOrTokenlessRetrieve(paymentSummary, clientSecret, customerSessionClientSecret);
        } catch (err) {
          alert(err);
        }
      } else if (LocationService.locationHasQRReader(userLocation)) {
        try {
          const token = await ParkingSessionService.getParkingToken({session: parkingSession, action: 'RETRIEVE', location: userLocation});
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
        } catch (err) {
          handleTokenError(err);
        }
      } else {
        mixpanel.track('OPEN_QR_TO_LEAVE', {session_id: parkingSession.id});
        setReadyToLeave(true);
      }
    }
  };

  const handleQRScannedForLeaving = (token) => {
    setReadyToLeave(false);
    setTokenForLeaving(token);
    mixpanel.track('SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
    ParkingSessionService.retrieve(token, parkingSession.id).then((res) => {
      let success = (res.success || res.payment?.success);
      if (success) {
        updateSession(res.session);
      } else if (res.paymentSummary) {
        setPaymentSummary(res.paymentSummary);
        setShowConfirmPayment({clientSecret: res.clientSecret, customerSessionClientSecret: res.customerSessionClientSecret});
      } else {
        console.error('unknown response', res);
      }
    }).catch((err) => {
      setTokenForLeaving(null);
      alert(err);
    });
  }

  const cancelScanQRForLeaving = () => {
    setReadyToLeave(false);
    mixpanel.track('CANCEL_SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
  }

  const handleQRScannedForReparkRetrieval = (token) => {
    setReadyToRepark(false);
    mixpanel.track('SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
    ParkingSessionService.retrieveVehicleForRepark(token, parkingSession.vehicle_id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    });
  }

  const cancelScanQRForReparkRetrieval = () => {
    setReadyToRepark(false);
    mixpanel.track('CANCEL_SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
  }

  /**
   * Safety check is done and ready to show QR scanner to restow
   */
  const onScanQRForRestowing = () => {
    if (LocationService.locationHasQRReader(userLocation)) {
      ParkingSessionService.getParkingToken({session: parkingSession, action: 'STOW'}).then((token) => {
        setParkingToken(token);
        setShowParkingToken(true);
        mixpanel.track('SCAN_QR_TO_RESTOW', {session_id: parkingSession.id});
      })
    } else {
      setScanQRForReparkRestowing(true);
      mixpanel.track('OPEN_QR_TO_RESTOW', {session_id: parkingSession.id});
    }
  }

  const onLeaveInstead = async () => {
    try {
      await confirm({ title: 'Are you sure you want to leave?', confirmationText: 'Confirm'});
      const updatedSession = await ParkingSessionService.leaveDuringRepark(parkingSession);
      updateSession(updatedSession);
    } catch (err) {
      if (err) { // will be undefined if not confirmed
        alert(err);
      }
    }
  }

  const handleQRScannedForReparkRestowing = (token) => {
    setScanQRForReparkRestowing(false);
    mixpanel.track('SCAN_QR_TO_RESTOW', {session_id: parkingSession.id});
    ParkingSessionService.stowVehicleForReparking(token, parkingSession.vehicle_id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    })
  }

  const cancelScanQRForReparkRestowing = () => {
    setScanQRForReparkRestowing(false);
    mixpanel.track('CANCEL_QR_TO_RESTOW', {session_id: parkingSession.id});
  }

  const cancelConfirmPayment = async () => {
    try {
      const session = await ParkingSessionService.cancelSessionPayment();
      updateSession(session);
      setShowConfirmPayment(null);
      mixpanel.track('CANCEL_CONFIRM_PAYMENT', {session_id: parkingSession.id});
    } catch (err) {
      alert(err);
    }
  };

  /**
   * After retrieval, reload the user and reset some global state
   */
  const postRetrieveCleanup = async () => {
    setCarouselProgress(0);
    setShowDriveAway(false);
  };

  const getRetrieveCardColor = () => {
    if (failed) {
      return 'red';
    }
    if (ParkingSessionService.isParked(parkingSession)) {
      return activeColor;
    }
    return mutedColor;
  };

  const getDiscountButtonBgColor = () => {
    return ParkingSessionService.isDiscountApplied(parkingSession) ? successColor : 'white';
  };

  const getDiscountButtonTextColor = () => {
    return ParkingSessionService.isDiscountApplied(parkingSession) ? 'white' : activeColor;
  };

  const getRetrieveButtonBgColor = () => {
    if (failed) {
      return 'red';
    }
    if (ParkingSessionService.isParked(parkingSession)) {
      return '#FC9F0D';
    }
    return null;
  };

  const ScanQRComp = (isOpen, title, onScan, onCancel) => {
    return <ScanQRDialog classes={classes} isOpen={isOpen} title={title} onScan={onScan} onCancel={onCancel} />
  }

  const ShowParkingToken = (isOpen) => {
    return <ShowQRDialog classes={classes} isOpen={isOpen} title={'Hold QR code up to any reader'} code={parkingToken} onCancel={onCancelShowParkingToken} />
  }

  // Renderer callback with condition
  const SessionCountDownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <span>Canceling parking session</span>;
    } else {
      return <span>Remaining time: &#160;&#160;{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>;
    }
  };

  SessionCountDownRenderer.propTypes = {
    minutes: PropTypes.node.isRequired,
    seconds: PropTypes.node.isRequired,
    completed: PropTypes.node.isRequired
  };

  /**
   * Show a modal dialog box with optional carousel progress bar and/or button
   * @param isOpen whether or not to show the dialog
   * @param title the title of the dialog
   * @param text the text in the dialog
   * @param showCarouselProgress whether or not to show the carousel progress bar
   * @param timeoutSecs Session timeout to show Countdown
   * @param cancelTimeoutFn if timeoutSecs is defined, then this is a function to call for canceling the countdown (if desired)
   * @param cancelTimeoutText if timeoutSecs is defined, then this is the text to show in the cancel button (default 'Cancel')
   */
  const ModalText = (isOpen, title, text, subtext, showCarouselProgress = false, timeoutSecs, cancelTimeoutFn, cancelTimeoutText = 'Cancel') => {
    return <Dialog open={isOpen}
                   aria-labelledby='alert-dialog-title'
                   aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <Typography component='h5' variant='h5'>
          {text}
        </Typography>
        {subtext && <Typography variant='body1'>
          {subtext}
        </Typography>}
        {timeoutSecs &&
          <>
            <Typography component='h6' variant='h6' style={{marginTop: 20}}>
              <Countdown
                date={Date.now() + (Math.max(timeoutSecs, 0) * 1000)}
                renderer={SessionCountDownRenderer}
              />
            </Typography>
            {cancelTimeoutFn &&
              <Button style={{marginTop: 10}}
                onClick={() => {
                  confirm({ description: 'Are you sure you want to cancel?', confirmationText: 'Confirm'})
                    .then(() => cancelTimeoutFn())
                    .catch(() => {
                      // to suppress console logging on cancel
                    });
                }}
              >
                {cancelTimeoutText}
              </Button> }
          </>
        }
        {showCarouselProgress && <LinearProgress variant='determinate' value={carouselProgress} />}
      </DialogContent>
    </Dialog>
  }

  const cancelSessionFn = async () => {
    const updatedSession = await ParkingSessionService.cancelSession(parkingSession?.id);
    if (updatedSession?.status) { // if an updated session was returned
      updateSession(updatedSession);
    }
  };

  const canParkAtLocation = (parkingOptions) => {
    if (parkingOptions.activeReservation) {
      return true;
    }
    if (parkingOptions.adHoc.numAvailable > 0) {
      return true;
    }
    for (const vehicle of parkingOptions.vehicles) {
      if (vehicle.isCarouselAssignment) {
        return true;
      }
    }
    return false;
  }

  const getTimeText = (timeStr) => {
    const startTime = momentTz(timeStr);
    const timezone = momentTz.tz.guess(); // Automatically guess the user's timezone
    const isToday = startTime.tz(timezone).isSame(moment().tz(timezone), 'day');
    return isToday
      ? startTime.format('[today at] h:mm a') // If it's today, format the message with "today"
      : startTime.format('[on] M/D [at] h:mm a'); // Otherwise, use "on M/D"
  }

  const getParkSubtext = (parkingOptions) => {
    if (parkingOptions.activeReservation) {
      const endTime = moment(parkingOptions.activeReservation.end_time).format('M/D [at] h:mm a');
      return `You have a reserved spot\nuntil ${endTime}`;
    }
    for (const vehicle of parkingOptions.vehicles) {
      if (vehicle.isCarouselAssignment) {
        return 'You have an assigned spot';
      }
    }

    let prefix = '';
    if (parkingOptions.upcomingReservation) {
      const formattedStartTime = getTimeText(parkingOptions.upcomingReservation.start_time);
      prefix = `You have an upcoming reservation\n${formattedStartTime}\n`;
    }
    if (parkingOptions.adHoc.numAvailable > 0) {
      const numAvailable = parkingOptions.adHoc.numAvailable;
      const numSUVAvailable = parkingOptions.adHoc.numSUVAvailable;
      const numEVAvailable = parkingOptions.adHoc.numEVAvailable;
      let description;
      if (numSUVAvailable === 0) {
        if (numEVAvailable === 0) {
          description = `Sedan-sized, no EV charging`
        } else {
          description = `Sedan-sized, some with EV charging`
        }
      } else {
        if (numEVAvailable === 0) {
          description = `No EV charging`
        } else {
          description = `Some with EV charging`
        }
      }
      return `${prefix}${numAvailable} ${numAvailable === 1 ? 'spot' : 'spots'} ${prefix ? 'currently ' : ''}available\n${description}`;
    }
    return '';
  }

  const getNoParkingSubtext = (parkingOptions) => {
    if (parkingOptions.upcomingReservation) {
      const formattedStartTime = getTimeText(parkingOptions.upcomingReservation.start_time);
      return `Your upcoming reservation \nbegins ${formattedStartTime}`;
    }
    return null;
  }

  /**
   * Geolocation effects
   */
  useEffect(() => {
    if (isGeolocationDebugMode) {
      console.log('userLocation', JSON.stringify(userLocation), 'geolocationActive', geolocationActive, 'isGeolocationDebugMode', isGeolocationDebugMode, 'latitude', latitude, 'longitude', longitude, 'distanceInFeet', distanceInFeet, 'outlierCount', outlierCount, 'positionCount', positionCount, 'bufferSize', bufferSize);
      let text;
      if (geolocationActive) {
        text = `${latitude.toFixed(4)},${longitude.toFixed(4)}`;
        if (distanceInFeet) {
          text += ` ${feetToDistanceString(distanceInFeet)} (Tot|X|Buf ${positionCount}|${outlierCount}|${bufferSize})`;
        }
      } else if (geolocationInitializing) {
        text = 'initializing geolocation'
      } else {
        text = 'geolocation inactive';
      }
      setGeoDebug(text);
    }
    if (!userLocation || !parkingOptions) {
      setParkingEnabled(false);
      setAllowQRParkingOverride(false);
      setParkingText('PARK');
      setParkingSubtext(null);
    } else if (!canParkAtLocation(parkingOptions)) {
      setParkingEnabled(false);
      setAllowQRParkingOverride(false);
      setParkingText(parkingOptions.isReservationOnly ? 'RESERVATIONS ONLY' : 'LOCATION FULL');
      setParkingSubtext(getNoParkingSubtext(parkingOptions));
    } else if (ParkingSessionService.isParked(parkingSession)) {
      setParkingEnabled(false);
      setAllowQRParkingOverride(false);
      setParkingText('PARK');
      setParkingSubtext(null);
    } else if (!userLocation.is_park_with_geolocation ||
      ('is_allow_geoparking' in user && !user.is_allow_geoparking) ||
      geolocationInitializing) {
      setParkingEnabled(true);
      setAllowQRParkingOverride(false);
      setParkingText('PARK');
      setParkingSubtext(getParkSubtext(parkingOptions));
    } else if (!geolocationActive) {
      setParkingEnabled(true);
      setAllowQRParkingOverride(false);
      setParkingText('PARK');
      setParkingSubtext(`${getParkSubtext(parkingOptions)}\nEnable location sharing to avoid\nexiting your vehicle.`);
    } else {
      if (distanceInFeet > userLocation.parking_radius_ft) {
        setParkingEnabled(false);
        setAllowQRParkingOverride(true);
        setParkingText(`${feetToDistanceString(distanceInFeet)} away`);
        setParkingSubtext(`${getParkSubtext(parkingOptions)}`)
      } else {
        setParkingEnabled(true);
        setAllowQRParkingOverride(false);
        setParkingText('PARK');
        setParkingSubtext(getParkSubtext(parkingOptions));
      }
    }
    // Allow retrieval flow / payment to start if there are valets and user is at location
    const driverIsOnsite = isTestMode || (geolocationActive && userLocation && distanceInFeet <= userLocation.parking_radius_ft);
    if (userLocation
        && ParkingSessionService.isParked(parkingSession)
        && isValetParked(parkingSession) // used to check for no QR readers as well, but this will put the car in the valet queue.
        && driverIsOnsite
    ) {
      setEnableTokenlessRetrieval(true);
    } else {
      setEnableTokenlessRetrieval(false);
    }
  }, [userLocation, geolocationActive, isGeolocationDebugMode, latitude, longitude, distanceInFeet, outlierCount, parkingSession, parkingOptions]);

  return (
    <AuthenticatedContainer>
      <>
        <GridItem xs={12} sm={12} md={8} align='center'>

          {ShowParkingToken(isShowParkingToken)}

          {/* Parking */}
          {ModalText(!!ParkingSessionService.isQueuedToPark(parkingSession) && !isValetParked(parkingSession), 'Queued to Park', 'Please wait for your turn to enter', null, false, null, cancelSessionFn)}
          {ModalText(!!ParkingSessionService.isReadyToEnter(parkingSession) && !isValetParked(parkingSession), 'Ready to Enter', `Please drive onto ${parkingSession?.carousel?.nickname}`, 'IMPORTANT: Fold side mirrors, and pull forward until tires hit the stop',false,
            parkingSession?.timeout_secs, cancelSessionFn, 'Cancel parking')}
          <SafetyChecklist
            isOpen={!!ParkingSessionService.isOnPallet(parkingSession) && !(scanQRForStowing || isShowParkingToken || isValetParked(parkingSession))}
            checklist={ParkingChecklist}
            stowFn={onScanQRForStowing}
            stowText={'Scan QR Code to Stow Vehicle'}
            cancelFn={cancelSessionFn}
            cancelText={'Cancel and Leave'}
          />
          {ScanQRComp(scanQRForStowing, 'Scan QR Code to Stow Vehicle', handleQRScannedForStowing)}
          {ModalText((!!ParkingSessionService.isReadyToStow(parkingSession) || !!ParkingSessionService.isStowing(parkingSession)) && !isValetParked(parkingSession), 'Stowing Your Car', 'Your car is being stowed...', null,true)}

          {/* Reparking */}
          {ScanQRComp(readyToRepark, 'Scan QR Code to Start Retrieval', handleQRScannedForReparkRetrieval, cancelScanQRForReparkRetrieval)}
          {ModalText(!!ParkingSessionService.isQueuedToRepark(parkingSession), 'In the Queue', `Your vehicle is in the queue to retrieve at ${parkingSession?.carousel?.nickname}`)}
          {ModalText(!!ParkingSessionService.isReparkingWaitingForCar(parkingSession), 'Retrieving Your Car', `Your vehicle is being retrieved at ${parkingSession?.carousel?.nickname}`, null,true)}
          { user?.is_free_parking ? // We don't yet handle payments when leaving during repark
            <SafetyChecklist
              isOpen={!!ParkingSessionService.isReparkingCarAvailable(parkingSession) && !(scanQRForReparkRestowing || isShowParkingToken|| isValetParked(parkingSession))}
              checklist={ReparkChecklist(parkingSession?.carousel?.nickname)}
              stowFn={onScanQRForRestowing}
              stowText={'Scan QR Code to Re-Stow Vehicle'}
              secondaryFn={onLeaveInstead}
              secondaryText={'Leave instead'}
            /> :
            <SafetyChecklist
              isOpen={!!ParkingSessionService.isReparkingCarAvailable(parkingSession) && !(scanQRForReparkRestowing || isShowParkingToken|| isValetParked(parkingSession))}
              checklist={ReparkChecklist(parkingSession?.carousel?.nickname)}
              stowFn={onScanQRForRestowing}
              stowText={'Scan QR Code to Re-Stow Vehicle'}
            />
          }
          {ScanQRComp(scanQRForReparkRestowing, 'Scan QR Code to Re-Stow Your Vehicle', handleQRScannedForReparkRestowing, cancelScanQRForReparkRestowing)}

          {/* Retrieving */}
          {ScanQRComp(readyToLeave, 'Scan QR Code to Start Checkout', handleQRScannedForLeaving, cancelScanQRForLeaving)}
          {/* Confirm payment: see below */}
          {ModalText(showSubmittingPayment, 'Submitting Payment', 'Your payment is being submitted...')}
          {ModalText(!!ParkingSessionService.isQueuedToRetrieve(parkingSession) && !isValetParked(parkingSession), 'In the Queue', `Your vehicle is in the queue to retrieve at ${parkingSession?.carousel?.nickname}`)}
          {ModalText(!!ParkingSessionService.isRetrievingWaitingForCar(parkingSession) && !isValetParked(parkingSession), 'Retrieving Your Car', `Your vehicle is being retrieved at ${parkingSession?.carousel?.nickname}`, null,true)}
          {ModalText(showDriveAway, 'Thank you for parking with Stak!', 'Please retrieve your car.', null,false)}

          {/* Valet queues */}
          {ModalText(!!ParkingSessionService.isInValetParkingQueue(parkingSession), 'In Valet Queue', `Your vehicle has been queued. Please leave your keys in the vehicle or hand them to the valet before you go.`)}
          {ModalText(!!ParkingSessionService.isAwaitingValetRetrieval(parkingSession), 'In Valet Queue', 'Your vehicle is in the valet retrieval queue.')}

          {/* Payment processing */}
          {ModalText(!!ParkingSessionService.isWaitingForPaymentToProcess(parkingSession), 'Payment Processing', `Your payment is processing...`)}

          {/* Valet retrieval notification */}
          <Dialog
            open={showValetRetrievedVehicleNotification}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Your Car Is Ready'}</DialogTitle>
            <DialogContent>
              <Typography variant='body1'>
                The valet has brought your vehicle.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowValetRetrievedVehicleNotification(false)} color='primary'>
                Got it!
              </Button>
            </DialogActions>
          </Dialog>

          {/* Confirm payment */}
          <Dialog
            open={!!showConfirmPayment}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
            maxWidth='sm'
          >
            <DialogTitle id='alert-dialog-title'>{'Confirm Payment'}</DialogTitle>
            <DialogContent>
              <TableContainer>
                <Table className={classes.paymentSummary} aria-label='spanning table'>
                  <TableBody>
                    <TableRow>
                      <TableCell>Subtotal</TableCell>
                      <TableCell align='right'>
                        {`$${paymentSummary?.totalAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Discount</TableCell>
                      <TableCell align='right'>
                        {`$${paymentSummary?.discountAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.paymentTotal}>Total</TableCell>
                      <TableCell align='right' className={classes.paymentTotal}>
                        {`$${paymentSummary?.chargedAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <DialogActions>
                {showConfirmPayment &&
                  <Elements stripe={stripePromise} options={{
                    clientSecret: showConfirmPayment.clientSecret,
                    customerSessionClientSecret: showConfirmPayment.customerSessionClientSecret
                  }}>
                    <StripeCheckout clientSecret={showConfirmPayment.clientSecret}
                                    cancelFn={cancelConfirmPayment}
                                    user={user}/>
                  </Elements>
                }
              </DialogActions>
            </DialogContent>
          </Dialog>

          {/* Apply discount code dialog */}
          <Dialog
            open={showApplyDiscount}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Apply Promo Code'}</DialogTitle>
            <DialogContent>
              <TextField
                error={isDiscountCodeFieldError}
                autoFocus
                margin='dense'
                label='Code'
                value={discountCode}
                onChange={(e) => {
                  setDiscountCode(e.target.value);
                }}
                type='text'
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDiscount} color='primary'>
                Cancel
              </Button>
              <Button onClick={handleApplyDiscountCode} color='primary' autoFocus>
                Apply
              </Button>
            </DialogActions>
          </Dialog>

          <Box>
            <Typography component='h5' variant='h5' style={{ color: 'black', padding: 10 }}>
              What would you like to do?
            </Typography>
          </Box>
        </GridItem>

        {/* car image */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box p={5}>
            <img src={car} alt='Home header' width='100%' />
          </Box>
        </GridItem>
        <Locations />

        {/* PARK box */}
        {ParkingSessionService.isShowParkButton(parkingSession) &&
          <>
            <GridItem xs={12} sm={12} md={8} align='center'>
              <Box>
                <Card
                  className={classes.root}
                  elevation={6}
                  style={{ height: '100%' }}
                  onClick={() => handlePark(false)}
                >
                  <CardMedia
                    className={classes.cover}
                    style={{ backgroundColor: parkingEnabled ? mainColor : mutedColor }}
                    title='Park'
                  >
                    <img src={parkIcon} alt='Park' />
                  </CardMedia>
                  <CardContent className={classes.park}>
                    <h4
                      className={parkingText?.length > 10 ? classes.smallCardText : classes.cardText}
                      style={{ color: parkingEnabled ? mainColor : mutedColor }}
                    >
                      {parkingText}
                    </h4>
                    {parkingSubtext &&
                      <div className={classes.parkingSubtext}>
                        <pre className={classes.parkButtonSubtext}>{parkingSubtext}</pre>
                      </div>
                    }
                    {isGeolocationDebugMode && geoDebug &&
                      <div className={classes.geoDebug}>
                        {geoDebug}
                      </div>
                    }
                  </CardContent>
                </Card>
              </Box>
            </GridItem>

            {allowQRParkingOverride &&
              <GridItem xs={12} sm={12} md={8} align='center'>
                <Button
                  onClick={() => handlePark(true)}
                  color='info'
                  variant='contained'
                  fullWidth
                  className={classes.qrOverrideButton}
                >
                  Park with QR Code
                </Button>
              </GridItem>
            }
          </>
        }

        {/* RETRIEVE box */}
        {ParkingSessionService.isShowParkingStatus(parkingSession) &&
          <GridItem xs={12} sm={12} md={8} align='left'>
            <Box style={{}}>
              <Card className={classes.root} style={{ height: '100%' }}>
                <CardMedia
                  className={classes.coverParked}
                  style={{
                    backgroundColor: getRetrieveCardColor(),
                  }}
                  title='retrieve'
                >
                  <img
                    src={retrieveIcon}
                    alt='Retrieve'
                    style={{ height: '30px' }}
                  />
                </CardMedia>
                <CardContent
                  className={classes.content}
                  style={
                    {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '100%',
                    }
                  }
                >
                  <GridItem style={{ padding: '0px' }} align='left'>
                    <h4
                      style={{
                        color: activeColor,
                        font: 'normal normal 900 20px/25px Lato',
                      }}
                    >
                      RETRIEVE
                      <RefreshIcon
                        style={{ float: 'right' }}
                        onClick={() => {
                          handleRefresh();
                        }}
                      />
                    </h4>
                  </GridItem>
                  <>
                    <Divider />
                    <Typography variant='body1' align='left' style={{ paddingBottom: 2 }}>
                      <b>Vehicle:</b> {parkingSession.vehicle_name}
                    </Typography>
                    <Typography variant='body1' align='left' style={{ paddingBottom: 2 }}>
                      <b>Parked at:</b>{' '}
                      {moment(parkingSession.start_time).format('MM/DD/YY h:mm a')}
                    </Typography>
                    <Typography variant='body1' align='left' style={{ paddingBottom: 5 }}>
                      {parkingSession.reservation ?
                        <>
                          <b>Reservation ends at:</b><br/>
                          {moment(parkingSession.reservation.end_time).format('MM/DD/YY h:mm a')}
                          <br/>
                          <b>Time remaining:</b> {parkTime}
                        </> :
                        <><b>Park timer:</b> {parkTime}</>}
                    </Typography>

                    { user && !user.is_free_parking && !parkingSession.reservation &&
                      <>
                        <Button
                          variant='contained'
                          fullWidth={false}
                          style={{
                            color: getDiscountButtonTextColor(),
                            marginTop: 20,
                            lineHeight: '1.2em',
                            backgroundColor: getDiscountButtonBgColor(),
                          }}
                          onClick={handleEnterDiscountCode}
                        >
                          {ParkingSessionService.isDiscountApplied(parkingSession)
                            ? ParkingSessionService.getDiscountDescription(parkingSession)
                            : 'Enter discount code'}
                        </Button>
                      </>
                    }
                    <Button
                      variant='contained'
                      fullWidth
                      disabled={false}
                      size='large'
                      style={{
                        color: 'white',
                        marginTop: 20,
                        lineHeight: '1.2em',
                        backgroundColor: getRetrieveButtonBgColor(),
                      }}
                      onClick={handleReadyToLeave}
                    >
                      READY TO LEAVE
                    </Button>

                    {!hasExpiredReservation(parkingSession) && !enableTokenlessRetrieval && !isValetParked(parkingSession) &&
                      <Button
                        variant='contained'
                        fullWidth
                        size='large'
                        style={{
                          color: 'darkslategray',
                          marginTop: 20,
                          lineHeight: '1.2em',
                          backgroundColor: 'lightyellow',
                        }}
                        onClick={handleGetSomethingFromVehicle}
                      >
                        Get something from vehicle
                      </Button>
                    }
                    {!enableTokenlessRetrieval && !isValetParked(parkingSession) &&
                      <Paper square elevation={0}>
                        <Typography className={classes.smallNote}>
                          You must be at the parking lot to scan a QR code in order to retrieve your vehicle.
                          {parkingOptions?.activeReservation &&
                            <><br/>You can leave and return as many times as needed during your reservation.</>
                          }
                        </Typography>
                      </Paper>
                    }
                    <Divider />
                    {failed && (
                      <Typography
                        variant='caption'
                        align='left'
                        style={{
                          paddingBottom: 10,
                          marginTop: 20,
                          color: 'red',
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    )}
                  </>
                </CardContent>
              </Card>
            </Box>
          </GridItem>
        }

        <GridItem xs={12} sm={12} md={8} align='center'>
          <HelpButton marginTop={'15px'}/>
        </GridItem>

        {/* snackbar */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box mt={5}>
            <Snackbar
              open={!!snackbarError}
              autoHideDuration={6000}
              onClose={() => {
                setSnackbarError(null);
              }}
            >
              <Alert
                onClose={() => {
                  setSnackbarError(null);
                }}
                severity='error'
              >
                {snackbarError}
              </Alert>
            </Snackbar>
          </Box>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}
